import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";

export default function EsriFeatureLayerMap(props) {
  const mapEl = useRef(null);

  useEffect(() => {
    let view;

    loadModules(
      ["esri/views/MapView", "esri/Map", "esri/layers/FeatureLayer"],
      { css: true },
    ).then(([MapView, Map, FeatureLayer]) => {
      let featLayer = new FeatureLayer(props.featureLayerConf);

      const map = new Map({ layers: [featLayer], ...props.mapConf });

      view = new MapView({
        map: map,
        container: mapEl.current,
        ...props.mapViewConf,
      });
    });
    return () => {
      if (!!view) {
        view.destroy();
        view = null;
      }
    };
  }, [props]);
  return <div ref={mapEl} {...props.divProps} />;
}

EsriFeatureLayerMap.defaultProps = {
  divProps: {
    style: { height: 720 },
  },
  featureLayerConf: {
    url: "",
    outFields: ["*"],
  },
  mapConf: {
    basemap: "gray-vector",
  },
  mapViewConf: {
    zoom: 12,
    center: [-76.4849944, 44.230689],
  },
};
