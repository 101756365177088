import * as React from "react";

import { Grid, Typography, Box } from "@mui/material";

import DashboardContainer from "../layout/DashboardContainer";
import DashboardTitle from "../typography/DashboardTitle";
import DashboardSubtitle from "../typography/DashboardSubtitle";
import VisualizationTitle from "../typography/VisualizationTitle";
import ImageAttribute from "../typography/ImageAttribute";
import DashboardHeader2 from "../typography/DashboardHeader2";
//Import Images
import HonestyImg from "../../static/img/indigenous_artwork/honesty_portia_chapman.jpg";
import HonourImg from "../../static/img/indigenous_artwork/honour_portia_chapman.jpg";
import PowerImg from "../../static/img/indigenous_artwork/power_portia_chapman.jpg";
import RespectImg from "../../static/img/indigenous_artwork/respect_portia_chapman.jpg";
import WisdomImg from "../../static/img/indigenous_artwork/wisdom_portia_chapman.jpg";

import MusicOnHoldDetails from "../cards/MusicOnHoldDetails";
import KingstonSoundsDetails from "../cards/KingstonSoundsDetails";

const Art = () => {
  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle>Art & Music</DashboardTitle>

      <Typography variant="body1" sx={{ mb: 5 }}>
        The Kingston area is home to many talented artists and musicians. Here
        you can see some examples of their work.
      </Typography>

      <Grid container spacing={7} sx={{ mb: 7 }}>
        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Local Artist</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            The illustrations represent words or concepts that speak to
            Indigenous "Ways of Knowing.” Portia Chapman's metaphorical artworks
            are hopeful, inspirational pieces that celebrate the unity and
            beauty of Creation. This artwork explores aspects of the Seven
            Grandfather Teachings with symbolism representative of
            Haudenosaunee, Anishinaabek, Metis and Inuit significance with
            underlying themes of honour, bravery and peace depicted within the
            images.
          </DashboardSubtitle>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ boxShadow: 0, borderRadius: "3px" }}
            >
              <Box sx={{ boxShadow: 4, m: 2, alignItems: "center" }}>
                <img src={HonestyImg} width="400" alt="Honesty" />
                <DashboardHeader2
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mb: 2 }}
                >
                  HONESTY
                </DashboardHeader2>
              </Box>
              <Box sx={{ boxShadow: 4, m: 2, alignItems: "center" }}>
                <img src={HonourImg} width="300" alt="Honour" />
                <DashboardHeader2
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mb: 2 }}
                >
                  HONOUR
                </DashboardHeader2>
              </Box>
              <Box sx={{ boxShadow: 4, m: 2, alignItems: "center" }}>
                <img src={PowerImg} width="300" alt="Power" />
                <DashboardHeader2
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mb: 2 }}
                >
                  POWER
                </DashboardHeader2>
              </Box>
              <Box sx={{ boxShadow: 4, m: 2, alignItems: "center" }}>
                <img src={WisdomImg} width="345" alt="Wisdom" />
                <DashboardHeader2
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mb: 2 }}
                >
                  WISDOM
                </DashboardHeader2>
              </Box>
              <Box sx={{ boxShadow: 4, m: 2, alignItems: "center" }}>
                <img src={RespectImg} width="300" alt="Respect" />
                <DashboardHeader2
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mb: 2 }}
                >
                  RESPECT
                </DashboardHeader2>
              </Box>
            </Grid>
          </Box>
          <ImageAttribute>
            Artwork by Portia Chapman, used with permission from the Queen's
            University Office of Indigenous Initiatives.
          </ImageAttribute>
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Local Music</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            A collection of videos from local musicians that highlight Kingston
            places. The videos are sourced from YouTube and used with permission
            from the artists.
          </DashboardSubtitle>
          <iframe
            width="100%"
            height="500"
            src="https://www.youtube-nocookie.com/embed/videoseries?list=PLT2f_0duSaksEzcj8uVEerq3jJrcGRmR5&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            YGK Music 2022 - Kingston Sounds
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            The City of Kingston's 2022 YGK Music playlists, which feature 30
            tracks created by local musicians, are now live for listening on the
            City website and call waiting system, Visit Kingston website, and
            SoundCloud. The playlists have also been produced for streaming in
            City facilities and public spaces, including Springer Market Square.{" "}
          </DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                title="YGK Music 2022 - Kingston Sounds"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1447749085&color=%23012a4a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            }
          </Box>
          <KingstonSoundsDetails />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            YGK Music 2022 - Music on Hold
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                title="YGK Music 2022 - Music on Hold"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1447691572&color=%23012a4a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            }
          </Box>
          <MusicOnHoldDetails />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>YGK Music - Music on Hold</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                title="YGK Music - Music on Hold"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1280141530&color=%23012a4a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            }
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>YGK Music - Kingston Sounds</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                title="YGK Music - Kingston Sounds"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1280138515&color=%23012a4a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            }
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>A Very YGK Holiday</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                title="A Very YGK Holiday"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1168571710&color=%23012a4a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            }
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>YGK Music - Traditional</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                title="YGK Music - Traditional"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/872106953&color=%23012a4a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            }
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>YGK Music - Contemporary</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                title="YGK Music - Contemporary"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/872089940&color=%23012a4a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            }
          </Box>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default Art;

Art.path = "/dashboards/Art";
Art.title = "Art and Music";
// Art.description = ""
