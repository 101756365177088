import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const getChartData = (utilitiesData, category) => {
  let chromaPalette = palette(category.length);
  let colourInd = 0;

  let datasets = category.map((categoryValue) => {
    let data_point = utilitiesData.data
      .filter((obj) => obj["Category"] === categoryValue)
      .map(function (obj) {
        return {
          y: obj["Value"] / 1000,
          x: obj["Year"] + "-" + obj["Month"],
        };
      });

    let set = {
      label: categoryValue,
      backgroundColor: chromaPalette[colourInd],
      borderColor: chromaPalette[colourInd],
      data: data_point,
    };

    colourInd += 1;
    return set;
  });

  let uniqueCategories = getUniqueCategory(utilitiesData.data);

  // IMPORTANT: making sure that we are always mapping the dataset with the most data first
  // (if we graph the dataset with less data first it causes a whole host of issues)
  datasets.sort(function (a, b) {
    return (
      uniqueCategories.indexOf(a.label) - uniqueCategories.indexOf(b.label)
    );
  });

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 20,
      },
      type: "time",
      time: {
        parser: "YYYY-MM",
        tooltipFormat: "MMM YYYY",
        unit: "month",
      },
    },
    y: {
      title: {
        text: "Consumption MWh",
        display: true,
      },
    },
  },
};

function getUniqueCategory(data) {
  // SORTING THIS ALPHABETICALLY SUCKS AND THE ONLY WAY TO GET THE DESIRED SORTING IS TO DO IT MANUALLY :(
  // return ["Total Consumption"].concat(data
  //     .filter(obj => obj["Category"] !== "Total Consumption")
  //     .map((obj) => obj["Category"])
  //     .filter(onlyUnique)
  //     .sort())

  const static_sorted_list = [
    "Residential",
    "Small Commercial",
    "Medium Commercial",
    "Large Commercial",
    "Total Consumption",
  ];

  const new_categories = data
    .filter((obj) => !static_sorted_list.includes(obj["Category"]))
    .map((obj) => obj["Category"])
    .filter(onlyUnique)
    .sort();

  return static_sorted_list.concat(new_categories);
}

const Utilities = (props) => {
  const sourceCode = "get_utilities";
  const {
    data: utilitiesData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  const [category, setCategory] = React.useState([
    "Small Commercial",
    "Medium Commercial",
    "Large Commercial",
  ]);
  const [uniqueCategory, setUniqueCategory] = React.useState([]);

  React.useEffect(() => {
    if (utilitiesData) {
      setUniqueCategory(getUniqueCategory(utilitiesData[0].data));
    }
  }, [utilitiesData]);

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(
      // On autofill we get the stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {utilitiesData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="category-multiple-checkbox-label">
                  Category
                </InputLabel>
                <Select
                  labelId="category-multiple-checkbox-label"
                  id="category-multiple-checkbox"
                  multiple
                  value={category}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput label="Category" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {uniqueCategory.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={category.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Line
            data={getChartData({ ...utilitiesData[0] }, category)}
            options={options}
          />
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default Utilities;

Utilities.defaultProps = {
  startDate: "2019-01",
};
