import React from "react";

import { Snackbar, Skeleton } from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import palette from "../../../helpers/palette";

const getChartData = (data) => {
  //Years and months are sorted in collection script

  // Get unique subdivisions
  let subdivisions = [];
  data[0].data.forEach((val) => {
    if (!subdivisions.includes(val["Subdivision"])) {
      subdivisions = [...subdivisions, val["Subdivision"]];
    }
  });

  let chromaPalette = palette(subdivisions.length);
  let colourInd = 0;

  // Create datasets based on subdivisions
  let datasets = subdivisions.map((subdivision) => {
    let data_point = data[0].data
      .filter((obj) => obj["Subdivision"] === subdivision)
      .map((obj) => {
        return {
          x: obj["Year"] + obj["Month"],
          y: obj["Total"],
        };
      });

    let set = {
      label: subdivision,
      data: data_point,
    };

    return set;
  });

  // sorting the datasets
  // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
  // 2- drawing datasets in alphabetical order by label
  datasets.sort(function (a, b) {
    if (a.data.length < b.data.length) {
      return -1;
    } else if (a.data.length > b.data.length) {
      return 1;
    } else {
      if (a.label <= b.label) {
        return -1;
      } else {
        return 1;
      }
    }
  });

  // setting the colours for each dataset AFTER we have sorted them
  datasets.forEach(function (item) {
    item["backgroundColor"] = chromaPalette[colourInd];
    item["borderColor"] = chromaPalette[colourInd];
    colourInd += 1;
  });

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    x: {
      title: {
        text: "Date",
        display: true,
      },
      type: "time",
      time: {
        parser: "YYYYMMM",
        tooltipFormat: "MMM, YYYY",
        unit: "month",
      },
    },
    y: {
      title: {
        text: "Number of Permanent Residents",
        display: true,
      },
    },
  },
};

const PermanentResidents = () => {
  const sourceCode = "get_permanent_residents";
  const { data, isFetching, isError } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {data && (
        <>
          <Line data={getChartData(data)} options={options} />
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default PermanentResidents;
