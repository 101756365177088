import React from "react";
import { Snackbar, Skeleton, Grid } from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";
import { Bar } from "react-chartjs-2";
import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const getChartData = (admissionFeeData) => {
  let years = Object.keys(admissionFeeData.data[0]);
  let labels = admissionFeeData.data
    .filter((obj) => obj[years[0]] !== null)
    .map((obj) => obj[years[0]])
    .filter(onlyUnique);

  let chromaPalette = palette(labels.length);
  let colourInd = 0;

  let datasets = labels.map((label) => {
    let data = years.map(
      (year) =>
        admissionFeeData.data.filter((obj) => obj[year] === label).length,
    );

    let dataset = {
      label: label,
      backgroundColor: chromaPalette[colourInd],
      borderColor: chromaPalette[colourInd],
      data: data,
      grouped: true,
    };

    colourInd += 1;
    return dataset;
  });

  return {
    labels: years,
    datasets: datasets,
  };
};

const options = {
  indexAxis: "y",
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    x: {
      stacked: false,
      title: {
        text: "Number of GLAMs that charge admission fees",
        display: true,
      },
    },
    y: {
      stacked: false,
      title: {
        text: "Year",
        display: true,
      },
    },
  },
};

const AdmissionFee = () => {
  const sourceCode = "get_heritage_admission";
  const {
    data: admissionFeeData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {admissionFeeData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData({ ...admissionFeeData[0] })}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default AdmissionFee;
