import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const ignoreRegions = [
  "Greater Napanee",
  "Lennox Addington",
  "Loyalist",
  "North & Central Frontenac",
  "South Frontenac",
  "Stone Mills & Addington Highlands",
];

const getChartData = (ediData, indicator, regions, allRegions) => {
  let chromaPalette = palette(allRegions.length);
  let colourInd = 0;

  // making sure colours always correspond to same geographies
  let staticPalette = {};
  for (let i = 0; i < allRegions.length; i++) {
    staticPalette[allRegions[i]] = chromaPalette[i];
  }

  let max = 0;
  let min = 100;

  let datasets = regions.map((region) => {
    let data_point = ediData.data
      .filter((obj) => obj["Indicator"] === indicator)
      .filter((obj) => obj["Region"] === region)
      .map(function (obj) {
        if (obj["Value"] > max) {
          max = obj["Value"];
        }
        if (obj["Value"] < min) {
          min = obj["Value"];
        }
        return {
          y: obj["Year"],
          x: obj["Value"],
        };
      });

    let set = {
      label: region,
      backgroundColor: chromaPalette[colourInd],
      borderColor: chromaPalette[colourInd],
      data: data_point,
    };

    colourInd += 1;
    return set;
  });

  let spread = max - min;

  if (max + spread > 100) {
    options.scales.x.max = 100;
  } else {
    options.scales.x.max = max + spread;
  }

  return {
    datasets: datasets,
  };
};

const options = {
  indexAxis: "y",
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    x: {
      stacked: false,
      title: {
        text: "Percent (%)",
        display: true,
      },
      min: 0,
    },
    y: {
      stacked: false,
      title: {
        text: "Cycle (year)",
        display: true,
      },
    },
  },
};

function getUniqueIndicator(data) {
  // keeping array of single indicators
  // (i.e., excluding indicators like "vulnerable on one or more domains")
  const single_indicators = data
    .map((obj) => obj["Indicator"])
    .filter(onlyUnique)
    .filter(function (value, index, arr) {
      return !value.match(/or more scales/i);
    })
    .sort();

  // keeping array of indicators which include multiple fields
  // (i.e., keeping ONLY indicators like "vulnerable on one or more domains")
  const multi_indicators = data
    .map((obj) => obj["Indicator"])
    .filter(onlyUnique)
    .filter(function (value, index, arr) {
      return value.match(/or more scales/i);
    })
    .sort();

  // putting multi indicators at the end of the list instead of sorting them alphabetically with the rest of the list
  return single_indicators.concat(multi_indicators);
}

function getUniqueRegion(data) {
  // keeping array of single regions
  const single_regions = data
    .map((obj) => obj["Region"])
    .filter(onlyUnique)
    .filter(function (value, index, arr) {
      return !value.match(/or more scales/i) && !ignoreRegions.includes(value);
    })
    .sort();
  return single_regions;
}

const EDI = () => {
  const sourceCode = "get_edi";
  const { data: ediData, isFetching, isError } = useRecord(sourceCode, null);
  console.log(ediData);
  const [indicator, setIndicator] = React.useState(
    "Vulnerable on 1 or more scales",
  );
  const [uniqueIndicator, setUniqueIndicator] = React.useState([]);

  const [region, setRegion] = React.useState([
    "Kingston Frontenac Lennox and Addington",
  ]);
  const [uniqueRegion, setUniqueRegion] = React.useState([]);

  React.useEffect(() => {
    if (ediData) {
      setUniqueIndicator(getUniqueIndicator(ediData[0].data));
    }
  }, [ediData]);

  React.useEffect(() => {
    if (ediData) {
      setUniqueRegion(getUniqueRegion(ediData[0].data));
    }
  }, [ediData]);

  const handleIndicatorChange = (event) => {
    const {
      target: { value },
    } = event;

    setIndicator(value);
  };

  const handleRegionChange = (event) => {
    const {
      target: { value },
    } = event;

    setRegion(value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {ediData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="indicator-simple-select-label">
                  Indicator
                </InputLabel>
                <Select
                  labelId="indicator-simple-select-label"
                  id="indicator-simple-select"
                  value={indicator}
                  label="Indicator"
                  onChange={handleIndicatorChange}
                >
                  {uniqueIndicator.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="region-simple-select-label">Region</InputLabel>
                <Select
                  labelId="descriptor-multiple-checkbox-label"
                  id="descriptor-multiple-checkbox"
                  multiple
                  value={region}
                  onChange={handleRegionChange}
                  input={<OutlinedInput label="Region" />}
                  renderValue={(selected) => selected.sort().join(", ")}
                >
                  {uniqueRegion.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={region.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData(
                  { ...ediData[0] },
                  indicator,
                  region,
                  uniqueRegion,
                )}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default EDI;
