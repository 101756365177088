import * as React from "react";
import { Typography } from "@mui/material";

export default function DashboardHeader2(props) {
  let typoProps = {
    variant: "h3",
    fontWeight: "light",
    color: "primary",
    fontSize: "2.6rem",
    ...props,
  };
  return React.createElement(Typography, typoProps);
}
