import * as React from "react";

import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import Employment from "../../pages/dashboards/Employment";
import BusinessResilience from "../../pages/dashboards/BusinessResilience";
import CommunityVulnerabilities from "../../pages/dashboards/CommunityVulnerabilities";
import Mobility from "../../pages/dashboards/Mobility";
import EnvironmentSustainability from "../../pages/dashboards/EnvironmentSustainability";
import Housing from "../../pages/dashboards/Housing";
import CulturalHeritage from "../../pages/dashboards/CulturalHeritage";
import Demographics from "../../pages/dashboards/Demographics";

import ListItemIcon from "@mui/material/ListItemIcon";
import HouseIcon from "@mui/icons-material/House";
import WorkIcon from "@mui/icons-material/Work";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import TrainIcon from "@mui/icons-material/Train";
import ForestIcon from "@mui/icons-material/Forest";
import MuseumIcon from "@mui/icons-material/Museum";
import PieChartIcon from "@mui/icons-material/PieChart";
import StoreIcon from "@mui/icons-material/Store";
import CommunitySafety from "../../pages/dashboards/CommunitySafety";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";

export default function DashboardsMenu(props) {
  return (
    <>
      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={BusinessResilience.path}
      >
        <ListItemIcon>
          <StoreIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={BusinessResilience.title} />
      </ListItemButton>

      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={Employment.path}
      >
        <ListItemIcon>
          <WorkIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={Employment.title} />
      </ListItemButton>
      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={CommunityVulnerabilities.path}
      >
        <ListItemIcon>
          <HealthAndSafetyIcon color="primary" />
        </ListItemIcon>

        <ListItemText primary={CommunityVulnerabilities.title} />
      </ListItemButton>
      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={Mobility.path}
      >
        <ListItemIcon>
          <TrainIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={Mobility.title} />
      </ListItemButton>
      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={EnvironmentSustainability.path}
      >
        <ListItemIcon>
          <ForestIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={EnvironmentSustainability.title} />
      </ListItemButton>
      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={Housing.path}
      >
        <ListItemIcon>
          <HouseIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={Housing.title} />
      </ListItemButton>
      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={CulturalHeritage.path}
      >
        <ListItemIcon>
          <MuseumIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={CulturalHeritage.title} />
      </ListItemButton>

      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={Demographics.path}
      >
        <ListItemIcon>
          <PieChartIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={Demographics.title} />
      </ListItemButton>
      <ListItemButton
        {...props.ListItemButton}
        component="a"
        href={CommunitySafety.path}
      >
        <ListItemIcon>
          <SafetyCheckIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={CommunitySafety.title} />
      </ListItemButton>
    </>
  );
}
