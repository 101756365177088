import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import palette from "../../../helpers/palette";

const getChartData = (climateData, measure, region, characteristic) => {
  let colourInd = 0;

  let numColours = getUniqueOptions(climateData.data).length;
  let chromaPalette = palette(numColours);

  // Convert chosen measure value to condensed version saved in database
  let condensedMeasure = "";
  if (measure.includes("capita")) {
    condensedMeasure = "t CO2e/capita";
  } else {
    condensedMeasure = "kt CO2e";
  }

  // Create dataValue variable to match name of data saved in the database
  let dataValue = characteristic + " (" + condensedMeasure + ")";

  let datasets = region.map((regionValue) => {
    let data_point = climateData.data
      .filter((obj) => obj["Region"] === regionValue)
      .map((obj) => ({ x: obj["Year"].toString(), y: obj[dataValue] }));

    let set = {
      label: regionValue + " " + characteristic + " Emissions",
      data: data_point,
    };

    return set;
  });

  // sorting the datasets
  datasets.sort(function (a, b) {
    return (
      ["Canada", "Ontario", "Kingston"].indexOf(a.label) -
      ["Canada", "Ontario", "Kingston"].indexOf(b.label)
    );
  });

  // setting the colours for each dataset AFTER we have sorted them
  datasets.forEach(function (item) {
    item["backgroundColor"] = chromaPalette[colourInd];
    item["borderColor"] = chromaPalette[colourInd];
    colourInd += 1;
  });

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "tonnes of CO2 emitted per capita",
      },
    },
    x: {
      title: {
        text: "Year",
        display: true,
      },
      type: "time",
      time: {
        parser: "YYYY",
        tooltipFormat: "YYYY",
        unit: "year",
      },
    },
  },
};

function getUniqueOptions(data) {
  // let unique = data
  //     .map(obj => obj["Region"])
  //     .filter(onlyUnique)

  // return Object.values({ ...unique })

  return ["Canada", "Ontario", "Kingston"];
}

const ClimateData = () => {
  const sourcecode = "get_climate_data";
  const {
    data: climateData,
    isFetching,
    isError,
  } = useRecord(sourcecode, null);

  const [measure, setMeasure] = React.useState(
    "tonnes of CO2 emitted per capita",
  );

  const [characteristic, setCharacteristic] = React.useState("Buildings");

  const [region, setRegion] = React.useState(["Canada", "Ontario", "Kingston"]);

  let possibleMeasures = [
    "kilotonnes of CO2 emitted",
    "tonnes of CO2 emitted per capita",
  ];
  let possibleRegions = ["Canada", "Ontario", "Kingston"];
  let possibleCharacteristics = [
    "Buildings",
    "Transport",
    "Agriculture",
    "Electricity",
    "Oil and gas",
  ];

  const handleMeasureChange = (event) => {
    const {
      target: { value },
    } = event;

    options.scales.y.title.text = event.target.value;

    setMeasure(value);
  };

  const handleRegionChange = (event) => {
    const {
      target: { value },
    } = event;
    setRegion(typeof value === "string" ? value.split(",") : value);
  };

  const handleCharacteristicChange = (event) => {
    const {
      target: { value },
    } = event;

    setCharacteristic(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {climateData && (
        <>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="measure-simple-select-label">
                  Measure
                </InputLabel>
                <Select
                  labelId="measure-simple-select-label"
                  id="measure-simple-select"
                  value={measure}
                  label="measure"
                  onChange={handleMeasureChange}
                >
                  {possibleMeasures.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="region-multiple-checkbox-label">
                  Region
                </InputLabel>
                <Select
                  labelId="region-multiple-checkbox-label"
                  id="region-multiple-checkbox"
                  multiple
                  value={region}
                  onChange={handleRegionChange}
                  input={<OutlinedInput label="region" />}
                  renderValue={(selected) =>
                    selected
                      .sort(function (a, b) {
                        return (
                          possibleRegions.indexOf(a) -
                          possibleRegions.indexOf(b)
                        );
                      })
                      .join(", ")
                  }
                >
                  {possibleRegions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={region.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="characteristic-simple-select-label">
                  Characteristic
                </InputLabel>
                <Select
                  labelId="characteristic-simple-select-label"
                  id="characteristic-simple-select"
                  value={characteristic}
                  onChange={handleCharacteristicChange}
                  label="characteristic"
                >
                  {possibleCharacteristics.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData(
                  { ...climateData[0] },
                  measure,
                  region,
                  characteristic,
                )}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourcecode} />
        </>
      )}
    </>
  );
};

export default ClimateData;
