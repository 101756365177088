import React from "react";
import { Snackbar, Skeleton, Grid } from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";
import { Bar } from "react-chartjs-2";
import { useRecord } from "../../../api/records";
import palette from "../../../helpers/palette";

const getChartData = (audienceEngagementData) => {
  let numColours = 1;
  let chromaPalette = palette(numColours);
  let datapoint = audienceEngagementData.data.map(function (obj) {
    return {
      y: obj["Breakoutpertype"],
      x: obj["Number"],
    };
  });

  let set = {
    label: "2021",
    backgroundColor: chromaPalette[0],
    borderColor: chromaPalette[0],
    data: datapoint,
  };
  return {
    datasets: [set],
  };
};

const options = {
  indexAxis: "y",
  scales: {
    x: {
      stacked: false,
      title: {
        text: "Number of GLAMs",
        display: true,
      },
    },
  },
};

const AudienceEngagement = () => {
  const sourceCode = "get_audience_engagement";
  const {
    data: audienceEngagementData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {audienceEngagementData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData({ ...audienceEngagementData[0] })}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default AudienceEngagement;
