import React from "react";
import { Snackbar, Skeleton, Grid } from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";
import { Bar } from "react-chartjs-2";
import { useRecord } from "../../../api/records";
import palette from "../../../helpers/palette";

const getChartData = (totalVisitorData) => {
  let labels = totalVisitorData.data.map((obj) => obj.Year);
  let chromaPalette = palette(labels.length);

  return {
    labels: totalVisitorData.data.map((obj) => obj.Year),
    datasets: [
      {
        backgroundColor: [chromaPalette[0], chromaPalette[1], chromaPalette[2]],
        data: totalVisitorData.data.map((obj) => obj["Visitors"]),
      },
    ],
  };
};

const options = {
  indexAxis: "x",
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Onsite Visitors",
    },
  },
  scales: {
    x: {
      stacked: false,
      title: {
        text: "Year",
        display: true,
      },
    },
    y: {
      stacked: false,
      title: {
        text: "Number of Onsite Visitors",
        display: true,
      },
    },
  },
};

const TotalVisitors = () => {
  const sourceCode = "get_visitor_numbers";
  const {
    data: totalVisitorData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {totalVisitorData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData({ ...totalVisitorData[0] })}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default TotalVisitors;
