import React from "react";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Snackbar,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { useRecord } from "../../../api/records";
import palette from "../../../helpers/palette";
import ErrorCard from "../../common/ErrorCard";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

function getGeoStyle(geo) {
  let chromaPalette = palette(3); // Adjusted to handle only three colors

  switch (geo) {
    case "Kingston, Ontario":
      return { colour: chromaPalette[0] };
    case "Ontario":
      return { colour: chromaPalette[1] };
    case "Canada":
      return { colour: chromaPalette[2] };
    default:
      return { colour: "#000000" }; // Default color (black)
  }
}

function getChartData(data, selectedGeo) {
  const filteredData = data.filter((obj) => typeof obj["VALUE"] === "number");

  const geoGroups =
    selectedGeo === "All"
      ? ["Kingston, Ontario", "Ontario", "Canada"]
      : [selectedGeo];

  let datasets = geoGroups.map((geo) => {
    let geoData = filteredData
      .filter((obj) => obj["GEO"].trim() === geo)
      .map((obj) => ({
        x: obj["REF_DATE"].toString(),
        y: obj["VALUE"],
      }));

    return {
      backgroundColor: getGeoStyle(geo).colour,
      borderColor: getGeoStyle(geo).colour,
      tension: 0.1,
      label: geo,
      data: geoData,
    };
  });

  return { datasets: datasets };
}

const options = {
  scales: {
    y: {
      title: { display: true, text: "GDP Of Location" },
    },
    x: {
      type: "time",
      time: { unit: "year" },
      title: { display: true, text: "Year" },
    },
  },
  maintainAspectRatio: false,
};

export default function GDPontariocakst() {
  const sourceCode = "get_gdp";
  const { data: gdpdata, isFetching, isError } = useRecord(sourceCode, null);

  const [selectedGeo, setSelectedGeo] = React.useState("Kingston, Ontario");

  const handleGeoChange = (event) => {
    setSelectedGeo(event.target.value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={500} />}
      {gdpdata?.length ? (
        <>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="geo-simple-select-label">Geography</InputLabel>
                <Select
                  labelId="geo-simple-select-label"
                  id="geo-simple-select"
                  value={selectedGeo}
                  label="Geography"
                  onChange={handleGeoChange}
                >
                  <MenuItem value="All">All</MenuItem>
                  {["Kingston, Ontario", "Ontario", "Canada"].map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ minHeight: "30em" }}>
            <Line
              data={getChartData(gdpdata[0].data, selectedGeo)}
              options={options}
            />
          </Box>
          <DataSourceRef code={sourceCode} />
        </>
      ) : (
        <ErrorCard />
      )}
    </>
  );
}
