import * as React from "react";
import { Typography } from "@mui/material";

export default function VisualizationTitle(props) {
  let typoProps = {
    variant: "h5",
    fontWeight: "medium",
    ...props,
  };
  return React.createElement(Typography, typoProps);
}
