import React from "react";

import { Alert, Box, Skeleton, Snackbar } from "@mui/material";

import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

import { useRecord } from "../../../api/records";

import DataSourceRef from "../../common/footnotes/DataSourceRef";
import palette from "../../../helpers/palette";
import ErrorCard from "../../common/ErrorCard";

function getChartData(businessIndexdata) {
  return {
    datasets: [
      {
        backgroundColor: palette(9)[0],
        borderColor: palette(9)[0],
        tension: 0.1,
        label: "Local Business Conditions Index",
        data: businessIndexdata.data.map((obj) => ({
          x: obj["REF_DATE"],
          y: obj["VALUE"],
        })),
      },
    ],
  };
}

const options = {
  scales: {
    y: {
      title: {
        display: true,
        text: "Local Business Conditions Index",
      },
    },
    x: {
      type: "time",
      time: {
        parser: "YYYY-MM-DD",
        tooltipFormat: "MMM DD, YYYY",
      },
    },
  },
  maintainAspectRatio: false,
};

export default function LocalBusinessConditionIndex() {
  const sourceCode = "get_business_condition_index";
  const {
    data: businessIndexData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={500} />}
      {businessIndexData?.length ? (
        <>
          <Box sx={{ minHeight: "30em" }}>
            <Line
              data={getChartData({ ...businessIndexData[0] })}
              options={options}
            />
          </Box>
          <DataSourceRef code={sourceCode} />
        </>
      ) : (
        <ErrorCard />
      )}
    </>
  );
}

LocalBusinessConditionIndex.defaultProps = {};
