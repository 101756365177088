import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import palette from "../../../helpers/palette";

const getChartData = (priceData, measure, structure) => {
  let colourInd = 0;

  let chromaPalette = palette(structure.length);

  let datasets = structure.map((structureValue) => {
    let housing = structureValue + " " + measure;

    let data_point = priceData.data.map((obj) => ({
      x: obj["Date"].toString(),
      y: obj[housing],
    }));

    // transparent points
    let pointColor = "rgba(0, 0, 0, 0)";

    let set = {
      label: structureValue,
      pointBackgroundColor: pointColor,
      pointBorderColor: pointColor,
      data: data_point,
    };

    return set;
  });

  // sorting the datasets
  datasets.sort(function (a, b) {
    return (
      [
        "Composite",
        "Single Family",
        "One Storey",
        "Two Storey",
        "Townhouse",
        "Apartment",
      ].indexOf(a.label) -
      [
        "Composite",
        "Single Family",
        "One Storey",
        "Two Storey",
        "Townhouse",
        "Apartment",
      ].indexOf(b.label)
    );
  });

  // setting the colours for each dataset AFTER we have sorted them
  datasets.forEach(function (item) {
    item["backgroundColor"] = chromaPalette[colourInd];
    item["borderColor"] = chromaPalette[colourInd];
    item["pointHoverBackgroundColor"] = chromaPalette[colourInd];
    item["pointHoverBorderColor"] = chromaPalette[colourInd];
    colourInd += 1;
  });

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      displayColors: false,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        // initial title of y axis since benchmark is the initial measure
        text: "Cost of Housing Benchmark",
      },
    },
    x: {
      type: "time",
      time: {
        parser: "YYYY-MM-DD",
        tooltipFormat: "MMM, YYYY",
        unit: "month",
      },
    },
  },
};

const HousingPrices = () => {
  const sourcecode = "get_housing_prices";
  const { data: priceData, isFetching, isError } = useRecord(sourcecode, null);

  const [measure, setMeasure] = React.useState("Benchmark");

  const [structure, setStructure] = React.useState([
    "Composite",
    "Single Family",
    "One Storey",
    "Two Storey",
    "Townhouse",
    "Apartment",
  ]);

  let possibleMeasures = ["Benchmark", "Index"];
  let possibleStructures = [
    "Composite",
    "Single Family",
    "One Storey",
    "Two Storey",
    "Townhouse",
    "Apartment",
  ];

  const handleMeasureChange = (event) => {
    const {
      target: { value },
    } = event;

    // Change title of y axis to match the measure currently being used
    options.scales.y.title.text = "Cost of Housing " + event.target.value;

    setMeasure(value);
  };

  const handleStructureChange = (event) => {
    const {
      target: { value },
    } = event;
    setStructure(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {priceData && (
        <>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={5}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="measure-simple-select-label">
                  Measure
                </InputLabel>
                <Select
                  labelId="measure-simple-select-label"
                  id="measure-simple-select"
                  value={measure}
                  label="measure"
                  onChange={handleMeasureChange}
                >
                  {possibleMeasures.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={7}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="structure-multiple-checkbox-label">
                  Structure
                </InputLabel>
                <Select
                  labelId="structure-multiple-checkbox-label"
                  id="structure-multiple-checkbox"
                  multiple
                  value={structure}
                  onChange={handleStructureChange}
                  input={<OutlinedInput label="structure" />}
                  renderValue={(selected) =>
                    selected
                      .sort(function (a, b) {
                        return (
                          possibleStructures.indexOf(a) -
                          possibleStructures.indexOf(b)
                        );
                      })
                      .join(", ")
                  }
                >
                  {possibleStructures.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={structure.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Line
                data={getChartData({ ...priceData[0] }, measure, structure)}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourcecode} />
        </>
      )}
    </>
  );
};

export default HousingPrices;
