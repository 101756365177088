import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import palette from "../../../helpers/palette";

const getChartData = (opioidData, measure, characteristic) => {
  let colourInd = 0;

  let chromaPalette = palette(characteristic.length);

  let datasets = characteristic.map((characteristicValue) => {
    let shortenedCharacterisitic = "";
    let shortenedMeasure = "";

    if (measure === "Rate per 100,000") {
      shortenedMeasure = "rate";
    } else {
      shortenedMeasure = "count";
    }

    if (characteristicValue === "Hospitalizations") {
      shortenedCharacterisitic = "Hosp";
    } else if (characteristicValue === "Emergency Department Visits") {
      shortenedCharacterisitic = "ED";
    } else {
      shortenedCharacterisitic = "Death";
    }

    let indicator = shortenedMeasure + shortenedCharacterisitic;

    let data_point = opioidData.data.map((obj) => ({
      x: obj["period"],
      y: obj[indicator],
    }));

    // transparent points
    let pointColor = "rgba(0, 0, 0, 0)";

    let set = {
      label: characteristicValue,
      pointBackgroundColor: pointColor,
      pointBorderColor: pointColor,
      data: data_point,
    };
    return set;
  });

  // sorting the datasets
  // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
  // 2- drawing datasets in alphabetical order by label
  datasets.sort(function (a, b) {
    if (a.data.length < b.data.length) {
      return -1;
    } else if (a.data.length > b.data.length) {
      return 1;
    } else {
      if (a.label <= b.label) {
        return -1;
      } else {
        return 1;
      }
    }
  });

  // setting the colours for each dataset AFTER we have sorted them
  datasets.forEach(function (item) {
    item["backgroundColor"] = chromaPalette[colourInd];
    item["borderColor"] = chromaPalette[colourInd];
    item["pointHoverBackgroundColor"] = chromaPalette[colourInd];
    item["pointHoverBorderColor"] = chromaPalette[colourInd];
    colourInd += 1;
  });

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      displayColors: false,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        // initial title of y axis since rate is the initial measure
        text: "Rate per 100,000",
      },
    },
    x: {
      type: "time",
      time: {
        parser: "YYYY - MM",
        tooltipFormat: "MMM, YYYY",
        unit: "month",
      },
    },
  },
};

const OpioidData = () => {
  const sourcecode = "get_opioid_data";
  const { data: opioidData, isFetching, isError } = useRecord(sourcecode, null);

  const [measure, setMeasure] = React.useState("Rate per 100,000");

  const [characteristic, setCharacteristic] = React.useState([
    "Emergency Department Visits",
    "Hospitalizations",
    "Deaths",
  ]);

  let possibleMeasures = ["Rate per 100,000", "Number of Cases"];
  let possibleCharacteristics = [
    "Emergency Department Visits",
    "Hospitalizations",
    "Deaths",
  ];

  const handleMeasureChange = (event) => {
    const {
      target: { value },
    } = event;

    // Change title of y axis to match the measure currently being used
    options.scales.y.title.text = event.target.value;

    setMeasure(value);
  };

  const handleCharacteristicChange = (event) => {
    const {
      target: { value },
    } = event;
    setCharacteristic(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {opioidData && (
        <>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={8}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="characteristic-multiple-checkbox-label">
                  Characteristic
                </InputLabel>
                <Select
                  labelId="characteristic-multiple-checkbox-label"
                  id="characteristic-multiple-checkbox"
                  multiple
                  value={characteristic}
                  onChange={handleCharacteristicChange}
                  input={<OutlinedInput label="characteristic" />}
                  renderValue={(selected) => selected.sort().join(", ")}
                >
                  {possibleCharacteristics.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={characteristic.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="measure-simple-select-label">
                  Measure
                </InputLabel>
                <Select
                  labelId="measure-simple-select-label"
                  id="measure-simple-select"
                  value={measure}
                  label="measure"
                  onChange={handleMeasureChange}
                >
                  {possibleMeasures.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Line
                data={getChartData(
                  { ...opioidData[0] },
                  measure,
                  characteristic,
                )}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourcecode} />
        </>
      )}
    </>
  );
};

export default OpioidData;
