import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
  ListItemText,
  Grid,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const getUngrouped = (groupBy) => {
  let ungroupedSet = "";

  if (groupBy === "Location") {
    ungroupedSet = [
      {
        element: "Census family type",
        defaultValue: "All family units in low income",
      },
      {
        element: "Family type composition",
        defaultValue: "Family types with or without children",
      },
    ];
  } else if (groupBy === "Census family type") {
    ungroupedSet = [
      { element: "Location", defaultValue: "Kingston" },
      {
        element: "Family type composition",
        defaultValue: "Family types with or without children",
      },
    ];
  } else {
    ungroupedSet = [
      { element: "Location", defaultValue: "Kingston" },
      {
        element: "Census family type",
        defaultValue: "All family units in low income",
      },
    ];
  }

  let firstUngrouped = ungroupedSet[0].element;
  let firstDefaultValue = ungroupedSet[0].defaultValue;

  let secondUngrouped = ungroupedSet[1].element;
  let secondDefaultValue = ungroupedSet[1].defaultValue;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label={firstDefaultValue}
            helperText={firstUngrouped}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label={secondDefaultValue}
            helperText={secondUngrouped}
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};

const getChartData = (
  lowIncomeFamiliesData,
  groupBy,
  descriptor,
  characteristic,
) => {
  let colourInd = 0;
  let ungrouped = "";

  let numColours = getUniqueDescriptor(
    lowIncomeFamiliesData.data,
    groupBy,
  ).length;

  let chromaPalette = palette(numColours);

  if (groupBy === "Location") {
    ungrouped = [
      {
        element: "Census family type",
        defaultValue: "All family units in low income",
      },
      {
        element: "Family type composition",
        defaultValue: "Family types with or without children",
      },
    ];
  } else if (groupBy === "Census family type") {
    ungrouped = [
      { element: "Location", defaultValue: "Kingston" },
      {
        element: "Family type composition",
        defaultValue: "Family types with or without children",
      },
    ];
  } else {
    ungrouped = [
      { element: "Location", defaultValue: "Kingston" },
      {
        element: "Census family type",
        defaultValue: "All family units in low income",
      },
    ];
  }

  let firstUngrouped = ungrouped[0].element;
  let firstDefaultValue = ungrouped[0].defaultValue;

  let secondUngrouped = ungrouped[1].element;
  let secondDefaultValue = ungrouped[1].defaultValue;

  let datasets = descriptor.map((descriptorValue) => {
    let data = lowIncomeFamiliesData.data
      .filter((obj) => obj["Statistics"] === characteristic)
      .filter((obj) => obj[groupBy] === descriptorValue)
      .filter((obj) => obj[firstUngrouped] === firstDefaultValue)
      .filter((obj) => obj[secondUngrouped] === secondDefaultValue)
      .map((obj) => ({ x: obj["REF_DATE"].toString(), y: obj["VALUE"] }));

    let dataset = {
      label: descriptorValue,
      data: data,
    };

    return dataset;
  });

  // sorting the datasets
  // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
  // 2- drawing datasets in alphabetical order by label
  datasets.sort(function (a, b) {
    if (a.data.length < b.data.length) {
      return -1;
    } else if (a.data.length > b.data.length) {
      return 1;
    } else {
      if (a.label <= b.label) {
        return -1;
      } else {
        return 1;
      }
    }
  });

  // setting the colours for each dataset AFTER we have sorted them
  datasets.forEach(function (item) {
    item["backgroundColor"] = chromaPalette[colourInd];
    item["borderColor"] = chromaPalette[colourInd];
    colourInd += 1;
  });

  let unitOfMeasurement = lowIncomeFamiliesData.data
    .filter((obj) => obj["Statistics"] === characteristic)
    .map((obj) => obj.UOM)[0];

  if (unitOfMeasurement === "Number") {
    options.scales.y.title.text = "Number of Families";
    options.plugins.tooltip = {
      callbacks: {
        label: function (context) {
          return (
            context.dataset.label + ": " + context.formattedValue + " families"
          );
        },
      },
    };
  } else if (unitOfMeasurement === "Dollars") {
    options.scales.y.title.text = "Dollars";
    options.plugins.tooltip = {
      callbacks: {
        label: function (context) {
          return context.dataset.label + ": $" + context.formattedValue;
        },
      },
    };
  }

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    x: {
      title: {
        text: "Year",
        display: true,
      },
      type: "time",
      time: {
        parser: "YYYY",
        tooltipFormat: "YYYY",
      },
    },
    y: {
      title: {
        display: true,
      },
    },
  },
};

function getUniqueGroupBy() {
  return ["Location", "Census family type", "Family type composition"].sort();
}

function getUniqueDescriptor(data, groupBy) {
  return data.map((obj) => obj[groupBy]).filter(onlyUnique);
}

function getUniqueCharacteristic(data) {
  return data.map((obj) => obj["Statistics"]).filter(onlyUnique);
}

const LowIncomeFamilies = (props) => {
  const sourceCode = "get_low_income_families";
  const {
    data: lowIncomeFamiliesData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  const [groupBy, setGroupBy] = React.useState("Location");
  const [uniqueGroupBy, setUniqueGroupBy] = React.useState([]);

  const [descriptor, setDescriptor] = React.useState(["Kingston", "Ontario"]);
  const [uniqueDescriptor, setUniqueDescriptor] = React.useState([]);

  const [characteristic, setCharacteristic] = React.useState(
    "Median after-tax income for family in low income",
  );
  const [uniqueCharacteristic, setUniqueCharacteristic] = React.useState([]);

  React.useEffect(() => {
    if (lowIncomeFamiliesData) {
      setUniqueGroupBy(getUniqueGroupBy());
    }
  }, [lowIncomeFamiliesData]);

  React.useEffect(() => {
    if (lowIncomeFamiliesData) {
      let descriptors = getUniqueDescriptor(
        lowIncomeFamiliesData[0].data,
        groupBy,
      );
      setUniqueDescriptor(descriptors);
      setDescriptor(descriptors);
    }
  }, [lowIncomeFamiliesData, groupBy]);

  React.useEffect(() => {
    if (lowIncomeFamiliesData) {
      setUniqueCharacteristic(
        getUniqueCharacteristic(lowIncomeFamiliesData[0].data),
      );
    }
  }, [lowIncomeFamiliesData]);

  const handleGroupByChange = (event) => {
    const {
      target: { value },
    } = event;

    setGroupBy(value);
  };

  const handleDescriptorChange = (event) => {
    const {
      target: { value },
    } = event;
    setDescriptor(typeof value === "string" ? value.split(",") : value);
  };

  const handleCharacteristicChange = (event) => {
    const {
      target: { value },
    } = event;

    setCharacteristic(value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {lowIncomeFamiliesData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="group-by-simple-select-label">
                  Group By...
                </InputLabel>
                <Select
                  labelId="group-by-simple-select-label"
                  id="group-by-simple-select"
                  value={groupBy}
                  label="Group By..."
                  onChange={handleGroupByChange}
                >
                  {uniqueGroupBy.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="descriptor-multiple-checkbox-label">
                  Descriptor
                </InputLabel>
                <Select
                  labelId="descriptor-multiple-checkbox-label"
                  id="descriptor-multiple-checkbox"
                  multiple
                  value={descriptor}
                  onChange={handleDescriptorChange}
                  input={<OutlinedInput label="Descriptor" />}
                  renderValue={(selected) => selected.sort().join(", ")}
                >
                  {uniqueDescriptor.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={descriptor.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={5}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="characteristic-simple-select-label">
                  Characteristic
                </InputLabel>
                <Select
                  labelId="characteristic-simple-select-label"
                  id="characteristic-simple-select"
                  value={characteristic}
                  label="Characteristic"
                  onChange={handleCharacteristicChange}
                >
                  {uniqueCharacteristic.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              {getUngrouped(groupBy)}
            </Grid>
            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData(
                  { ...lowIncomeFamiliesData[0] },
                  groupBy,
                  descriptor,
                  characteristic,
                )}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default LowIncomeFamilies;

LowIncomeFamilies.defaultProps = {};
