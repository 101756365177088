import * as React from "react";
import Grid from "@mui/material/Grid";

import { Box, CardActionArea } from "@mui/material";
import Kam from "../../static/img/base_site_logos/KAM_Logo_Ext_Horiz_Lft_2022_RGB.png";
import Mitacs from "../../static/img/base_site_logos/mitacs_transparent.png";
import Canada from "../../static/img/base_site_logos/sshrc-fip-full-color-eng.png";
import Kingston from "../../static/img/base_site_logos/image-2022-08-25-14-30-55-550.png";
import CityofKingston from "../../static/img/base_site_logos/CoK_blue_500.png";
import CACLogo from "../../static/img/base_site_logos/CAC-logo.png";
import QueensLogo from "../../static/img/base_site_logos/Queens_Logo.png";
import IndigenousInitiatives from "../../static/img/base_site_logos/Indigenous_initiatives_centred colour.png";

export default function PartnerLogo(props) {
  return (
    <>
      <Box sx={{ alignItems: "center", mt: 4 }}>
        <Grid
          container
          spacing={5}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://www.queensu.ca/"
              target="_blank"
            >
              <img src={QueensLogo} width="210" alt="Queen's University" />
            </CardActionArea>
          </Grid>
          <Grid item>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://cac.queensu.ca/"
              target="_blank"
            >
              <img src={CACLogo} width="150" alt="Mitacs" />
            </CardActionArea>
          </Grid>
          <Grid item>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://www.queensu.ca/indigenous/"
              target="_blank"
            >
              <img
                src={IndigenousInitiatives}
                width="200"
                alt="Indigenous Initiatives"
              />
            </CardActionArea>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://kingstoncanada.com/"
              target="_blank"
            >
              <img
                src={Kingston}
                width="280"
                alt="Kingston Economic Development"
              />
            </CardActionArea>
          </Grid>
          <Grid item>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://www.cityofkingston.ca/"
              target="_blank"
            >
              <img src={CityofKingston} width="200" alt="City Of Kingston" />
            </CardActionArea>
          </Grid>
          <Grid item>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://www.kingstonmuseums.ca/"
              target="_blank"
            >
              <img
                src={Kam}
                width="200"
                alt="Kingston and Area Association of Museums, Art Galleries, and Historic Sites"
              />
            </CardActionArea>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <Grid item>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://discover.mitacs.ca/innovationroi/?gclid=CjwKCAjw2OiaBhBSEiwAh2ZSP7kn6zpkgUmxrYGvOG97DDSvK7oqoGD7n9rlL6dd9RtnqvklFAZl4BoCdV4QAvD_BwE"
              target="_blank"
            >
              <img src={Mitacs} width="200" alt="Mitacs" />
            </CardActionArea>
          </Grid>
          <Grid item sx={{ width: "100%", maxWidth: "880px" }}>
            <CardActionArea
              sx={{ borderRadius: 2 }}
              component="a"
              href="https://www.sshrc-crsh.gc.ca/home-accueil-eng.aspx"
              target="_blank"
            >
              <img
                src={Canada}
                width="100%"
                alt="Social Sciences and Humanities Research Council of Canada"
              />
            </CardActionArea>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
