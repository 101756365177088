import React from "react";

import { Snackbar, Skeleton } from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const getChartData = (populationPyramidData) => {
  let genders = populationPyramidData.data
    .map((obj) => obj["Gender"])
    .filter(onlyUnique);

  let years = populationPyramidData.data
    .map((obj) => obj["Year"])
    .filter(onlyUnique);

  years.sort(function (a, b) {
    return b.localeCompare(a);
  });

  let combinations = [];

  for (const gender of genders) {
    for (const year of years) {
      combinations.push([year, gender]);
    }
  }

  let chromaPalette = palette(combinations.length);
  let colourInd = 0;
  let chartType = "";
  let drawingOrder = 0;

  let datasets = combinations.map((combo) => {
    let data_point = populationPyramidData.data
      .filter((obj) => obj["Year"] === combo[0])
      .filter((obj) => obj["Gender"] === combo[1])
      .map(function (obj) {
        return {
          y: obj["Age Group"],
          x: obj["Value"],
        };
      });

    // if our current year is equal to the most recent year
    if (combo[0] === years[0]) {
      chartType = "bar";
      drawingOrder = 100;
    } else {
      chartType = "line";
      drawingOrder = 1;
    }

    let set = {
      label: combo[1] + ", " + combo[0],
      backgroundColor: chromaPalette[colourInd],
      borderColor: chromaPalette[colourInd],
      data: data_point,
      type: chartType,
      order: drawingOrder,
    };

    colourInd += 1;
    return set;
  });

  options.scales.x.ticks.maxTicksLimit = populationPyramidData.data
    .map((obj) => obj["Age Group"])
    .filter(onlyUnique).length;

  return {
    datasets: datasets,
  };
};

const options = {
  indexAxis: "y",
  reponsive: true,
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return context.dataset.label + ": " + Math.abs(context.raw.x);
        },
      },
    },
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 21,
        callback: function (value) {
          return Math.abs(value);
        },
      },
      title: {
        text: "Number of People",
        display: true,
      },
      stacked: true,
    },
    y: {
      title: {
        text: "Age Group",
        display: true,
      },
      stacked: true,
    },
  },
};

const PopulationPyramid = (props) => {
  const sourceCode = "get_population_pyramid";
  const {
    data: populationPyramidData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {populationPyramidData && (
        <>
          <Bar
            data={getChartData({ ...populationPyramidData[0] })}
            options={options}
          />
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default PopulationPyramid;

PopulationPyramid.defaultProps = {
  startDate: "2019-01",
};
