import * as React from "react";
import { Typography } from "@mui/material";

export default function HeaderSubtitle(props) {
  let typoProps = {
    variant: "body1",
    mt: 1,
    mb: 4,
    ...props,
  };
  return React.createElement(Typography, typoProps);
}
