import * as React from "react";

import DataDictionary from "../../pages/DataDictionary";

import BookmarkIcon from "@mui/icons-material/BookmarkBorder";
import { Chip } from "@mui/material";

export default function DataSourceRef(props) {
  return (
    <Chip
      size="small"
      sx={{ my: 1 }}
      icon={<BookmarkIcon fontSize="small" />}
      label="See data source"
      component="a"
      href={DataDictionary.path + "?source=" + props.code}
      clickable
    />
  );
}

DataSourceRef.defaultProps = {
  code: "",
};
