import * as React from "react";

import { Box } from "@mui/system";
import {
  Container,
  Skeleton,
  Snackbar,
  Alert,
  Typography,
  Stack,
} from "@mui/material";
import {
  Timeline as MuiTimeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab/";

import CircleIcon from "@mui/icons-material/Circle";

import Copyright from "../common/Copyright";

import { useRecord } from "../../api/records";
import DataSourceRef from "../common/footnotes/DataSourceRef";

const Timeline = (props) => {
  const sourceCode = "get_timeline";
  const {
    data: timelineData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <Box>
      <Container maxWidth="lg" sx={{ mt: 7, mb: 4 }}>
        <Typography variant="h3" fontWeight="bold" color="primary">
          COVID Timeline
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 5 }}>
          A view at the major events
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          This timeline highlights chronologically ‘key’ events in Ontario that
          occurred during the COVID-19 pandemic. These events can be filtered by
          impact level. Information and impactful events were provided by the
          City of Kingston and sourced through Global News.
        </Typography>
        {isError && (
          <Snackbar
            open={true}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              Something went wrong. Please try reloading the page.
            </Alert>
          </Snackbar>
        )}
        {isFetching && (
          <Stack spacing={5}>
            {[...Array(5).keys()].map((i) => (
              <Skeleton key={i} variant="rectangular" height={100} />
            ))}
          </Stack>
        )}
        {timelineData && (
          <>
            <Box sx={{ maxHeight: "800px", overflow: "auto" }}>
              <MuiTimeline sx={{ maxWidth: "1000px" }}>
                {timelineData[0].data.map((ev, i) => (
                  <TimelineItem key={i}>
                    <TimelineOppositeContent sx={{ maxWidth: "1px" }} />
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color={"grey"}>
                        <CircleIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ minWidth: "250px" }}>
                      <Typography
                        sx={{ mt: 5 }}
                        variant="body2"
                        color="text.secondary"
                      >
                        {ev.Date}
                      </Typography>
                      <Typography sx={{ mt: 1 }} variant="body1">
                        {ev.Event}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </MuiTimeline>
            </Box>
            <DataSourceRef code={sourceCode} />
          </>
        )}
        <Copyright />
      </Container>
    </Box>
  );
};

export default Timeline;

Timeline.path = "/timeline";
Timeline.title = "COVID Timeline";
Timeline.description =
  "A snapshot of the major event during the COVID-19 pandemic.";
