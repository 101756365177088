import React from "react";

import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Snackbar,
} from "@mui/material";

import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

import { useRecord } from "../../../api/records";

import DataSourceRef from "../../common/footnotes/DataSourceRef";
import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

function getMeasureStyle(key) {
  key = String(key);

  let chromaPalette = palette(9);

  switch (key) {
    case "Active businesses":
      return {
        colour: chromaPalette[0],
      };
    case "Closing businesses":
      return {
        colour: chromaPalette[1],
      };
    case "Continuing businesses":
      return {
        colour: chromaPalette[2],
      };
    case "Entrants":
      return {
        colour: chromaPalette[3],
      };
    case "Exits":
      return {
        colour: chromaPalette[4],
      };
    case "Opening businesses":
      return {
        colour: chromaPalette[5],
      };
    case "Reopening businesses":
      return {
        colour: chromaPalette[6],
      };
    case "Temporary closures":
      return {
        colour: chromaPalette[7],
      };
    default:
      return {
        colour: chromaPalette[8],
      };
  }
}

function getUniqueMeasures(data, geo, industry) {
  return data
    .filter((obj) => obj["GEO"].trim() === geo)
    .filter((obj) => obj["Industry"].trim() === industry)
    .filter((obj) => typeof obj["VALUE"] === "number")
    .map((o) => o["Business dynamics measure"])
    .filter(onlyUnique);
}

function getUniqueIndustries(data, geo) {
  return data
    .filter((obj) => obj["GEO"].trim() === geo)
    .filter((obj) => typeof obj["VALUE"] === "number")
    .map((o) => o.Industry)
    .filter(onlyUnique);
}

function getUniqueGeo(data) {
  //return data.map((o) => o.GEO.trim()).filter(onlyUnique).sort()
  return ["Kingston", "Ontario", "Canada"];
}

function getChartData(openclosedata, industry, geo, measure) {
  /*Check if Status = Null. If so, then retreive the value and industry as a point. X value = date range, Y-value = 'Value'*/
  openclosedata.data = openclosedata.data
    .filter((obj) => obj["GEO"].trim() === geo)
    .filter((obj) => obj["Industry"].trim() === industry)
    .filter(
      (obj) => measure.indexOf(obj["Business dynamics measure"].trim()) > -1,
    )
    .filter((obj) => typeof obj["VALUE"] === "number");
  let datasets = measure.map((meas_val) => {
    let meas_data = openclosedata.data
      .filter((obj) => obj["Business dynamics measure"].trim() === meas_val)
      .map((obj) => ({ x: obj["REF_DATE"], y: obj["VALUE"] }));
    let set = {
      backgroundColor: getMeasureStyle(meas_val).colour,
      borderColor: getMeasureStyle(meas_val).colour,
      tension: 0.1,
      label: meas_val,
      data: meas_data,
    };
    return set;
  });
  return {
    datasets: datasets,
  };
}

const options = {
  scales: {
    y: {
      title: {
        display: true,
        text: "Number of businesses",
      },
    },
    x: {
      type: "time",
      time: {
        parser: "YYYY-MM",
      },
    },
  },
  maintainAspectRatio: false,
};

export default function OpenClose({ initIndustry, initGeo, initMeasure }) {
  const sourceCode = "get_open_close";
  const {
    data: openclosedata,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  const [geo, setGeo] = React.useState(initGeo);
  const [industry, setIndustry] = React.useState(initIndustry);
  const [measure, setMeasure] = React.useState(initMeasure);

  const checkMeas = (newIndustry, newGeo = geo) => {
    if (openclosedata) {
      let measureOpts = getUniqueMeasures(
        openclosedata[0].data,
        newGeo,
        newIndustry,
      );
      let updatedMeas = measure.filter(
        (meas) => measureOpts.indexOf(meas) >= 0,
      );
      if (updatedMeas.length === 0) {
        updatedMeas = [measureOpts[0]];
      }
      setMeasure(updatedMeas);
    }
  };

  const checkIndustry = (newGeo) => {
    if (openclosedata) {
      let industryOpts = getUniqueIndustries(openclosedata[0].data, newGeo);
      if (industryOpts.indexOf(industry) === -1) {
        setIndustry(industryOpts[0]);
        checkMeas(industryOpts[0], newGeo);
      } else {
        checkMeas(industry, newGeo);
      }
    }
  };

  const handleGeoChange = (event) => {
    setGeo(event.target.value);
    checkIndustry(event.target.value);
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
    checkMeas(event.target.value);
  };

  const handleMeasureChange = (event) => {
    let value = event.target.value;
    setMeasure(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={500} />}
      {openclosedata && (
        <>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="geo-simple-select-label">Geography</InputLabel>
                <Select
                  labelId="geo-simple-select-label"
                  id="geo-simple-select"
                  value={geo}
                  label="Geography"
                  onChange={handleGeoChange}
                >
                  {getUniqueGeo(openclosedata[0].data).map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="industry-simple-select-label">
                  Industry
                </InputLabel>
                <Select
                  labelId="industry-simple-select-label"
                  id="industry-simple-select"
                  value={industry}
                  label="Industry"
                  onChange={handleIndustryChange}
                >
                  {getUniqueIndustries(openclosedata[0].data, geo)
                    .sort()
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="measure-multiple-checkbox-label">
                  Display
                </InputLabel>
                <Select
                  labelId="measure-multiple-checkbox-label"
                  id="measure-multiple-checkbox"
                  multiple
                  value={measure}
                  onChange={handleMeasureChange}
                  input={<OutlinedInput label="Display" />}
                  renderValue={(selected) => selected.sort().join(", ")}
                >
                  {getUniqueMeasures(openclosedata[0].data, geo, industry)
                    .sort()
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={measure.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ minHeight: "30em" }}>
            <Line
              data={getChartData(
                { ...openclosedata[0] },
                industry,
                geo,
                measure,
              )}
              options={options}
            />
          </Box>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
}

OpenClose.defaultProps = {
  initGeo: "Kingston",
  initIndustry: "Business sector industries [T004]",
  initMeasure: ["Opening businesses", "Closing businesses"],
};
