import { Box } from "@mui/material";

export default function ErrorCard() {
  return (
    <Box
      sx={{
        mb: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {" "}
      We can't load this visualization at the moment. Please try again later.
    </Box>
  );
}
