import DashboardContainer from "../../layout/DashboardContainer";
import DashboardTitle from "../../typography/DashboardTitle";
import { Grid } from "@mui/material";
import VisualizationNarrative from "../../typography/VisualizationNarrative";
import VisualizationTitle from "../../typography/VisualizationTitle";
import FireandRescue from "../../indicators/community_safety/FireandRescue";

const CommunitySafety = (props) => {
  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>{CommunitySafety.title}</DashboardTitle>

      <Grid item xs={12} lg={12}>
        <VisualizationTitle sx={{ mt: 7, mb: 1 }}>
          Kingston Fire & Rescue Incident Reports
        </VisualizationTitle>
        <VisualizationNarrative sx={{ mb: 4 }}>
          This map contains information about incidents attended to by Kingston
          Fire & Rescue since 2016. Each incident includes call type, incident
          type, location and date.
        </VisualizationNarrative>
        <FireandRescue />
      </Grid>
    </DashboardContainer>
  );
};
export default CommunitySafety;

CommunitySafety.path = "/dashboards/communitysafety";
CommunitySafety.title = "Community Safety";
CommunitySafety.description =
  "This dashboard includes indicators that are related to Kingston community safety.";
