import * as React from "react";

import { Grid, Tab, Tabs, Box } from "@mui/material";

import DashboardContainer from "../../layout/DashboardContainer";
import DashboardTitle from "../../typography/DashboardTitle";
import DashboardSubtitle from "../../typography/DashboardSubtitle";
import VisualizationTitle from "../../typography/VisualizationTitle";

import EsriFeatureLayerMap from "../../indicators/environment/EsriFeatureLayerMap";

import Waste from "../../indicators/environment/Waste";
import HazardousWaste from "../../indicators/environment/HazardousWaste";
import FarmLocations from "../../indicators/environment/FarmLocations";
import Schedule78 from "../../indicators/environment/Schedule78";
import ClimateData from "../../indicators/environment/ClimateData";
import Utilities from "../../indicators/environment/Utilities";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

const treeGroupTemplate = {
  title: "Tree group information",
  content: "<ul><li>Tree group ID: {TREE_GROUP_ID}</li></ul>",
};

const treeTemplate = {
  title: "Tree information",
  content:
    "<ul><li>Type: {TYPE}</li>" +
    "<li>Tree ID: {TREE_ID}</li>" +
    "<li>Is in a park? {IN_PARK}</li></ul>",
};

const treeRenderer = {
  type: "simple",
  symbol: {
    type: "simple-marker",
    size: 6,
    color: "green",
    outline: {
      width: 0.4,
      color: "white",
    },
  },
};

const EnvironmentSustainability = () => {
  const [currTab, setCurrTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrTab(newValue);
  };

  const tabArray = [
    {
      label: "Trees",
      source: <DataSourceRef code="get_tree_parks_cycling_planning_dev" />,
      component: (
        <EsriFeatureLayerMap
          featureLayerConf={{
            outFields: ["*"],
            url: "https://services1.arcgis.com/5GRYvurYYUwAecLQ/arcgis/rest/services/Trees1/FeatureServer",
            popupTemplate: treeTemplate,
            renderer: treeRenderer,
          }}
        />
      ),
    },
    {
      label: "Tree Groups",
      source: <DataSourceRef code="get_tree_parks_cycling_planning_dev" />,
      component: (
        <EsriFeatureLayerMap
          featureLayerConf={{
            outFields: ["*"],
            url: "https://services1.arcgis.com/5GRYvurYYUwAecLQ/arcgis/rest/services/TreeGroup/FeatureServer",
            popupTemplate: treeGroupTemplate,
          }}
        />
      ),
    },
    {
      label: "Parks",
      source: <DataSourceRef code="get_tree_parks_cycling_planning_dev" />,
      component: (
        <iframe
          title="Parks"
          width="100%"
          height="720"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          src="https://www.arcgis.com/apps/webappviewer/index.html?id=867c788006b6448d9bd4bcb9033dac65"
        ></iframe>
      ),
    },
    {
      label: "Cycling Facilities",
      source: <DataSourceRef code="get_tree_parks_cycling_planning_dev" />,
      component: (
        <iframe
          title="Cycling Facilities"
          width="100%"
          height="720"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          src="https://www.arcgis.com/apps/webappviewer/index.html?id=e1617bcfb2554bf2a38678b735a44ec5"
        ></iframe>
      ),
    },
    {
      label: "Planning and Development",
      source: <DataSourceRef code="get_tree_parks_cycling_planning_dev" />,
      component: (
        <iframe
          title="Planning and Development"
          width="100%"
          height="720"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          src="https://www.arcgis.com/apps/MapSeries/index.html?appid=343a968a042a4a3ba52cd398795c58e5"
        ></iframe>
      ),
    },
  ];

  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>
        {EnvironmentSustainability.title}
      </DashboardTitle>
      <DashboardSubtitle sx={{ mb: 5 }}>
        This interactive dashboard explores environmental indicators across a
        broad spectrum. Natural areas, cycling facilities, as well as a variety
        of elements from the City of Kingston's Official Plan and other
        community initiatives are included on this dashboard. Envirnmental
        considerations have become more prominent in the context of COVID-19 and
        the ongoing climate crisis.
      </DashboardSubtitle>
      <Grid container spacing={7} sx={{ mb: 7 }}>
        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            City of Kingston Natural Heritage System
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This map shows the Natural Heritage System (NHS) as identified
            within the City of Kingston Official Plan based on work which began
            in 2004, when the City worked on the Central Cataraqui Region
            Natural Heritage Study to identify a system of natural features and
            areas including wetlands and woodlands across the City. Within the
            Official Plan there are a total of 23,768 hectares identified and
            delineated within the NHS while the total area within the City
            boundary is 52, 946 hectares. The legend on this visualization
            provides a breakdown of the features and areas that make up the NHS
            in Kingston as identified in the Official Plan.
          </DashboardSubtitle>
          <Schedule78 />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            Trees, Parks, Cycling, Planning and Development
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            A variety of information can be viewed by clicking the tabs located
            at the top of this visualization. A tree inventory is displayed when
            you click on the “Trees” tab where you will find out if the tree is
            coniferous or deciduous, an ID number and if it is in a park. Parks
            and Conservation Authority areas are also displayed here along with
            some site details. The Planning and Development tab can be explored
            with regard to development activity, building permits, and the
            Official Plan Land Use map.
          </DashboardSubtitle>
          <Box
            sx={{
              maxWidth: { xs: 320, sm: 700, md: "100%" },
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={currTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabArray.map((tabElem, index) => {
                return <Tab key={"tab-item-" + index} label={tabElem.label} />;
              })}
            </Tabs>
          </Box>
          {tabArray.map((tabElem, index) => {
            return (
              <Box
                key={index}
                sx={{ display: index === currTab ? "block" : "none" }}
              >
                {tabElem.component}
                {tabElem.source}
              </Box>
            );
          })}
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Census of Agriculture</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <iframe
            title="Census of Agriculture"
            src="https://www.arcgis.com/apps/dashboards/86e2abcf7d8a4cb1acccbfc16f102a4f"
            width="100%"
            height="800"
          ></iframe>
          <DataSourceRef code="agriculture_census" />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>City-Owned Industrial Lands</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <iframe
            title="City-Owned Industrial Lands"
            src="https://cityofkingston.maps.arcgis.com/apps/MapJournal/index.html?appid=6193c31abcb841b1b98e2c741ba2cc47"
            width="100%"
            height="700"
          ></iframe>
          <DataSourceRef code="industrial_lands" />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle> Sanitary Overflow</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            Explore this visualization, provided by Utilities Kingston to learn
            about the added risk of pollution that can occur after heavy rain
            fall. KFL&A Public Health cautions that lake bacteria levels are
            higher after a heavy rainfall and swimming is not recommended for 48
            hours. During and after heavy rainfall, sewer overflow points may
            discharge diluted sewage into the environment. Utilities Kingston
            provides this information on a real-time map to help you make a
            better-informed decision about recreational water use.
          </DashboardSubtitle>
          <Box sx={{ display: "block" }}>
            {
              <iframe
                title="Sanitary Overflow"
                width="100%"
                height="800px"
                src="https://cityofkingston.maps.arcgis.com/apps/MapSeries/index.html?appid=1773aee6064a4f1086b6347d9eb3a51c"
                frameborder="0"
                scrolling="no"
              ></iframe>
            }{" "}
            <DataSourceRef code="sanitary_overflow" />{" "}
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Garbage Collected</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows the number of Kilograms (Kg) of waste collected in
            the City during 2019 through to the end of 2021.
          </DashboardSubtitle>
          <Waste industry={"Garbage"} />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Recycling Collected</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows the number of Kilograms (Kg) of recyclable material
            collected in the City during 2019 through to the end of 2021.
          </DashboardSubtitle>
          <Waste industry={"Recycling"} />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            Household Hazardous Waste Collected
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows the number of Kilograms (Kg) of household hazardous
            waste collected in the City from May 2019 to November 2021.{" "}
          </DashboardSubtitle>
          <HazardousWaste />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Local Food Producers</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            {" "}
            This map shows the farms that service Kingston and the Greater
            Kingston Area. These farms produce for restaurants and for farmers’
            markets primarily from April-October of each year. During the early
            phases of the COVID-19 pandemic, many transitioned to online sales/
            platforms as well as Community-Supported Agriculture (CSAs), and
            have since returned to selling at locations such as the Memorial
            Centre Farmers’ Market or Market Square Public Market. The volume of
            farms that service the area reflects the maintenance of farm land
            versus urban sprawl - allowing Kingston to shorten some food chains
            and provide many residents with both farm fresh produce and options
            to support local economies. Many of the farms also work with food
            organizations in Kingston to donate produce or allow for gleaning
            opportunities in order to reach lower-income and vulnerable
            individuals/ families who cannot afford the retail price of fresh,
            local produce.
          </DashboardSubtitle>
          <FarmLocations />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Carbon Dioxide Emissions</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph allows you to explore CO2 emissions in Kingston compared
            to Ontario and Canada either as a per capita rate or total
            emissions. It allows you to understand the performance of Kingston
            within the broader country and the province. This highlights the
            regional differences in emissions and allows you to compare
            Kingston’s relative and overall performance.
          </DashboardSubtitle>
          <ClimateData />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            Total Monthly Electricity Consumption
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            Utilities Kingston provides a unique multi-utility service model in
            Kingston that offers measurable benefits in customer service and
            cost efficiencies to electricity, natural gas and water/sewer
            customers in Kingston Hydro’s distribution area. Kingston Hydro
            distributes electricity in a safe, reliable and cost-efficient
            manner to homes, businesses and public institutions across Kingston.
            By monitoring usage and responding to community needs, Utilities
            Kingston on behalf of Kingston Hydro can help support new local
            economic development opportunities and provide essential
            infrastructure maintenance to meet the needs of the future customer
            and keep pace with change.
          </DashboardSubtitle>
          <Utilities />
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default EnvironmentSustainability;

EnvironmentSustainability.path = "/dashboards/environment";
EnvironmentSustainability.title = "Environment";
EnvironmentSustainability.description =
  "This dashboard explores indicators for measuring Kingston's environment and examines the impact of the COVID-19 pandemic on the environment.";
