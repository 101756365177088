import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export default function LandAcknowledgement(props) {
  return (
    <Box
      color="primary.contrastText"
      sx={{ backgroundColor: "primary.main", p: 3, mt: 2, borderRadius: 1 }}
    >
      <Typography color="white" align="center">
        The City of Kingston acknowledges that we are on the traditional
        homeland of the Anishinaabe, Haudenosaunee and the Huron-Wendat, and
        thank these nations for their care and stewardship over this shared
        land. Today, the city is committed to working with Indigenous peoples
        and all residents to pursue a united path of reconciliation.
      </Typography>
    </Box>
  );
}
