import * as React from "react";

import { Grid, Typography } from "@mui/material";

import DashboardContainer from "../../layout/DashboardContainer";
import DashboardTitle from "../../typography/DashboardTitle";
import DashboardHeader3 from "../../typography/DashboardHeader3";
import HeaderSubtitle from "../../typography/HeaderSubtitle";
import VisualizationTitle from "../../typography/VisualizationTitle";
import VisualizationNarrative from "../../typography/VisualizationNarrative";

import EsriFeatureLayerMap from "../../indicators/cultural_heritage/EsriFeatureLayerMap";
import CharitableStatus from "../../indicators/cultural_heritage/CharitableStatus";
import OperatingBudget from "../../indicators/cultural_heritage/OperatingBudget";
import VolunteerHours from "../../indicators/cultural_heritage/VolunteerHours";
import VolunteerNumbers from "../../indicators/cultural_heritage/VolunteerNumbers";
import AdmissionFee from "../../indicators/cultural_heritage/AdmissionFee";
import FederalRevenue from "../../indicators/cultural_heritage/FederalRevenue";
import ProvincialRevenue from "../../indicators/cultural_heritage/ProvincialRevenue";
import MunicipalRevenue from "../../indicators/cultural_heritage/MunicipalRevenue";
import NonGovernmentRevenue from "../../indicators/cultural_heritage/NonGovernmentRevenue";
import ContractEmpNumbers from "../../indicators/cultural_heritage/ContractEmpNumbers";
import FulltimeEmpNumbers from "../../indicators/cultural_heritage/FulltimeEmpNumbers";
import ParttimeEmpNumbers from "../../indicators/cultural_heritage/ParttimeEmpNumbers";
import OpenMonths from "../../indicators/cultural_heritage/OpenMonths";
import OpenDays from "../../indicators/cultural_heritage/OpenDays";
import TotalVisitors from "../../indicators/cultural_heritage/TotalVisitors";
import OnlineVisitors from "../../indicators/cultural_heritage/OnlineVisitors";
import SiteClosures from "../../indicators/cultural_heritage/SiteClosures";
import AudienceEngagement from "../../indicators/cultural_heritage/AudienceEngagement";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

// const permitTemplate = {
//   title: "Heritage Permit",
//   content:
//     "<ul><li>Record ID: {RECORD_ID}</li>" +
//     "<li>Permit Address: {ADDR_FULL}</li>" +
//     "<li>Description: {DESCRIPTION}</li>" +
//     "<li>Applicant: {APPLICANT}</li>" +
//     "<li>Dash Record: <a href='{DASH_URL}'>Dash Link</a></li>" +
//     "<li>Application Status: {RECORD_STATUS}</ul>",
// };

const unescoTemplate = {
  title: "Unesco Heritage Site",
  content:
    "<ul><li>Site Name: {NOTES_NAME_OF_BLDG}</li>" +
    "<li>Site Address: {ADDRESS}</li>" +
    "<li>Date of Construction: {DATE_OF_CONSTRUCTION}</li>",
};

const nhsTemplate = {
  title: "National Historic Site",
  content:
    "<ul><li>Historic Site Name: {NOTES_NAME_OF_BLDG}</li>" +
    "<li>Federal Heritage Status: {FEDERAL_HERITAGE_STATUS}</li>" +
    "<li>Ontario Heritage Act Status: {OHA_DESIGNATION}</li>" +
    "<li>Date of Construction: {DATE_OF_CONSTRUCTION}</li>",
};

const museumsTemplate = {
  title: "GLAM information",
  content:
    "<ul><li>Name: {USER_Name}</li>" +
    "<li>Address: {USER_Address}</li>" +
    "<li>Within the City of Kingston: {USER_Within_the_City_of_Kingsto}</li>" +
    "<li>Site type: {USER_Site_type}</li>" +
    "<li>Jurisdiction: {USER_Jurisdiction}</li>" +
    "<li>Site within or associated with: {USER_Site_within_or_associated_}</li>" +
    "<li>Website: {USER_Website}</li>" +
    "<li>Notes: {USER_Notes}</li></ul>",
};

const museumsRenderer = {
  type: "simple",
  symbol: {
    type: "simple-marker",
    size: 10,
    color: "#6baed6",
    outline: {
      width: 0.4,
      color: "white",
    },
  },
};

const cultHerDashTemplate = {
  title: "Details",
  content:
    "<ul><li>Monument Name: {Monument_Name}</li>" +
    "<li>Address Location: {Address_location}</li>" +
    "<li>Designation Jurisdiction: {Designation_Jurisdiction}</li>" +
    "<li>Monument Types: {Monument_Types}</li>" +
    "<li>Notes: {Notes}</li>",
};

const districtTemplate = {
  title: "District information",
  content:
    "<ul><li>Name: {Name}</li>" +
    "<li>District ID: {HTG_DIST_ID}</li>" +
    "<li>Status: {STATUS}</li></ul>",
};

// const permitRenderer = {
//   type: "simple",
//   symbol: {
//     type: "simple-marker",
//     size: 6,
//     color: "purple",
//     outline: {
//       width: 0.4,
//       color: "white",
//     },
//   },
// };

const heritageSite = {
  title: "National Historic Site",
  content:
    "<ul><li>Name of Building: {NOTES_NAME_OF_BLDG}</li>" +
    "<li>Address: {ADDRESS	}</li>" +
    "<li>Heritage Site ID: {HERITAGE_SITE_ID	}</li>" +
    "<li>Heritage File Number: {HERITAGE_FILE_NO}</li>" +
    "<li>Designated Property: {DESIGNATED}</li>" +
    "<li>Part V Designation: {	PART_V}</li>" +
    "<li>Part IV Designation: {PART_IV}</li>" +
    "<li>OHA Bylaw: {OHA_BY_LAW}</li>" +
    "<li>OHA Designation: {OHA_DESIGNATION}</li>" +
    "<li>Bylaw Amendments: {BY_LAW_AMENDTS}</li>" +
    "<li>Heritage Easement: {EASEMENT}</li>" +
    "<li>Easement Registration: {EASEMENT_RGSTD}</li>" +
    "<li>Notice of Intent: {NOTICE_OF_INTENT}</li>" +
    "<li>Internal Designation: {INTERNAL_DESIGNATION}</li>" +
    "<li>Statement of Significance 1: {STMT_OF_SIGNIF_1}</li>" +
    "<li>Statement of Significance 2: {STMT_OF_SIGNIF_2}</li>" +
    "<li>Volume Page: {VOLUME_PAGE}</li>" +
    "<li>Date of Construction: {DATE_OF_CONSTRUCTION}</li>" +
    "<li>Heritage Area: {HERITAGE_AREA}</li>" +
    "<li>Demolished: {DEMOLISHED}</li>" +
    "<li>Demolished Date: {DEMOLISHED_DATE}</li>" +
    "<li>De-Designation: {DE_DESIGNATED}</li>" +
    "<li>De-Designation Date: {DE_DESIGNATED_DATE}</li>" +
    "<li>Interior Features incl: {INTERIOR_FEATURES_INCLUDED}</li>" +
    "<li>Disclaimer: {DISCLAIMER}</li>" +
    "<li>GLOBALID: {GLOBALID}</li>" +
    "<li>SHAPE.AREA: {Shape__Area}</li>" +
    "<li>SHAPE.LEN: {Shape__Length}</li>",
};

const heritageProperty = {
  title: "National Historic Site",
  content:
    "<ul><li>Name of Building: {NOTES_NAME_OF_BLDG}</li>" +
    "<li>Address: {ADDRESS	}</li>" +
    "<li>Heritage Site ID: {HERITAGE_SITE_ID	}</li>" +
    "<li>Heritage File Number: {HERITAGE_FILE_NO}</li>" +
    "<li>Listed Property: {PROP_OF_CULT_HTG_VAL}</li>" +
    "<li>Notice of Intent: {NOTICE_OF_INTENT}</li>" +
    "<li>Statement of Significance 1: {STMT_OF_SIGNIF_1}</li>" +
    "<li>Date of Construction: {DATE_OF_CONSTRUCTION}</li>" +
    "<li>Heritage Area: {HERITAGE_AREA}</li>" +
    "<li>De-Listed: {DE_LISTED}</li>" +
    "<li>De-Listed Date: {DE_LISTED_DATE}</li>" +
    "<li>Disclaimer: {DISCLAIMER}</li>" +
    "<li>GLOBALID: {GLOBALID}</li>" +
    "<li>SHAPE.AREA: {Shape__Area}</li>" +
    "<li>SHAPE.LEN: {Shape__Length}</li>",
};
const CulturalHeritage = () => {
  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>{CulturalHeritage.title}</DashboardTitle>
      <Typography variant="body1" sx={{ mb: 5 }}>
        Cultural heritage represents a broad range of elements including
        heritage, cultural, creative activities and resources that contribute to
        the public vitality and wellbeing of communities, their residents, and
        visitors. Cultural Heritage Institutions include art galleries,
        libraries, archives, and museums, collectively known as GLAMs.
        Indicators reveal structural aspects of the local cultural heritage
        sector such as location and governance of institutions, season of
        operation, heritage designations, plaques and monuments, heritage
        properties and districts, as well as employment information; economic
        details related to funding investment, sources of revenue, and admission
        charges; COVID-impacts are measured through site closures, visitor
        numbers and changes to audience engagement approaches. Data users of
        this dashboard may wish to contact the Kingston and Area Association of
        Museums, Art Galleries and Historic Sites for further information.
      </Typography>

      <Grid container spacing={7} sx={{ mb: 3 }}>
        <Grid item xs={12} lg={12} sx={{ mb: 5 }}>
          <DashboardHeader3>Sector Infrastructure Indicators</DashboardHeader3>
          <HeaderSubtitle>
            The Kingston area is well recognized for its cultural heritage and
            wide variety of GLAMs &#8211; each offering a range of experiences
            and opportunities for engagement. What is less well-recognized is
            what shapes and drives the cultural heritage sector in terms of
            practical infrastructure.
          </HeaderSubtitle>

          <Grid item xs={12} lg={12}>
            <Grid container spacing={8} sx={{ mb: 10 }}>
              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  GLAMs and Monuments Maps
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Kingston and area is a diverse and heritage dense region,
                  hosting a variety of GLAMs and heritage monuments with a wide
                  range of jurisdictional authorities overseeing their purposes,
                  operations, and governance.
                </VisualizationNarrative>

                <Typography sx={{ fontWeight: "bold", mb: 3 }}>
                  GLAMs
                </Typography>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://services1.arcgis.com/5GRYvurYYUwAecLQ/arcgis/rest/services/Museums_KAMs_QU_view/FeatureServer",
                    popupTemplate: museumsTemplate,
                    renderer: museumsRenderer,
                  }}
                />
                <DataSourceRef code="get_glams" />

                <Typography sx={{ fontWeight: "bold", my: 3 }}>
                  Historical Monuments
                </Typography>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://services1.arcgis.com/5GRYvurYYUwAecLQ/arcgis/rest/services/Cultural_Heritage_Dashboard_view/FeatureServer",
                    popupTemplate: cultHerDashTemplate,
                  }}
                />
                <DataSourceRef code="get_historical_monuments" />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Heritage Conservation District (HCD) Map
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  The City of Kingston has three established Heritage
                  Conservation Districts: Barriefield Village (1980); Market
                  Square (1985); and Old Sydenham (2013). A Heritage
                  Conservation District is a defined area noted for its heritage
                  character and assets. Barriefield Village was the first
                  designated HCD in Ontario.
                </VisualizationNarrative>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://utility.arcgis.com/usrsvcs/servers/1c3e1df8da3a427cbea660cd6ba3d056/rest/services/Heritage_District/FeatureServer/0?f=pjson",
                    popupTemplate: districtTemplate,
                  }}
                />
                <DataSourceRef code="get_heritage_conservation_district_map" />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  World Heritage and National Historic Sites of Canada Maps
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  The Rideau Canal and Kingston Fortifications UNESCO World
                  Heritage Site, along with many National Historic Sites, are
                  part of Kingston’s cultural heritage landscape. These places
                  have value and interest to citizens of the world, Canadians,
                  and people of the region and local area.
                </VisualizationNarrative>

                <Typography sx={{ fontWeight: "bold", mb: 3 }}>
                  World Heritage Sites
                </Typography>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://api.cityofkingston.ca/gis_unfed/rest/services/Planning/UNESCO/FeatureServer",
                    popupTemplate: unescoTemplate,
                  }}
                />
                <DataSourceRef code="get_world_heritage_sites" />

                <Typography sx={{ fontWeight: "bold", my: 3 }}>
                  National Historic Sites
                </Typography>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://api.cityofkingston.ca/gis_unfed/rest/services/Planning/NationalHistoricSite/FeatureServer",
                    popupTemplate: nhsTemplate,
                  }}
                />
                <DataSourceRef code="get_national_hiatoric_sites" />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Heritage Properties (Ontario Heritage Act) Maps
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Within the City of Kingston are a variety of heritage
                  properties that are of cultural heritage value or interest.
                  The Heritage Properties Register illustrated in these maps
                  contain both designated and listed built heritage, under the
                  Ontario Heritage Act, which represent multiple architectural
                  forms, materials, and in some cases the architects who
                  designed them.
                </VisualizationNarrative>
                <Typography sx={{ fontWeight: "bold", my: 3 }}>
                  Designated Heritage Site
                </Typography>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://api.cityofkingston.ca/gis_unfed/rest/services/Planning/DesignatedHeritageSite/FeatureServer",
                    popupTemplate: heritageSite,
                  }}
                />
                <DataSourceRef code="get_designated_heritage_site" />

                <Typography sx={{ fontWeight: "bold", my: 3 }}>
                  Listed Heritage Property
                </Typography>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://api.cityofkingston.ca/gis_unfed/rest/services/Planning/ListedHeritageProperty/FeatureServer",
                    popupTemplate: heritageProperty,
                  }}
                />
                <DataSourceRef code="get_listed_heritage_property" />
              </Grid>

              {/* <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Heritage Permits Map (City of Kingston)
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  The Ontario Heritage Act (Sections 33, 34 & 41) requires
                  owners to obtain permission from their city council for any
                  alteration or demolition to a designated property if it is
                  likely to affect the property's heritage attributes. This map
                  depicts the number of active heritage permit applications
                  within the City of Kingston.
                </VisualizationNarrative>
                <EsriFeatureLayerMap
                  featureLayerConf={{
                    outFields: ["*"],
                    url: "https://services1.arcgis.com/5GRYvurYYUwAecLQ/ArcGIS/rest/services/Active_Heritage_Permits/FeatureServer/0",
                    popupTemplate: permitTemplate,
                    renderer: permitRenderer,
                  }}
                />
                <DataSourceRef code="get_heritage_permits_map" />
              </Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle>Human Resources — Paid</VisualizationTitle>
            <HeaderSubtitle>
              GLAMs require a variety of staff to ensure appropriate operations.
              These charts indicate employee type and the number of employees
              currently sustaining site operations for GLAMs within the Kingston
              region. Between 2019 and 2021, the majority of Kingston area GLAMs
              operate with less than 5 employees in any given category.
            </HeaderSubtitle>

            <Grid container spacing={8} sx={{ mb: 10 }}>
              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Full-Time Regular Employees
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  A full-time, regular employee works 30+ hours per week on a
                  regular basis and is issued a T4 for tax purposes.
                </VisualizationNarrative>
                <FulltimeEmpNumbers />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Part-Time Regular Employees
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  A part-time, regular employee works less than 30 hours per
                  week on a regular basis and is issued a T4 for tax purposes.
                </VisualizationNarrative>
                <ParttimeEmpNumbers />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>Contract Employees</VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Contract employees work for a time-specific period and purpose
                  and are not issued a T4 for tax purposes.
                </VisualizationNarrative>
                <ContractEmpNumbers />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle>Human Resources — Volunteer</VisualizationTitle>
            <HeaderSubtitle>
              GLAMs also depend on volunteers to support site operations,
              mission and mandate. Volunteers can fulfil a variety of roles,
              ranging from customer service, project work, governance, and even
              fulfill staff functions. Some GLAMs are entirely volunteer
              operated.
            </HeaderSubtitle>

            <Grid container spacing={7} sx={{ mb: 5 }}>
              <Grid item xs={12} lg={12}>
                <VisualizationTitle>Number of Volunteers</VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Volunteer numbers for GLAMs typically fluctuate year on year.
                  Unsurprisingly, overall more GLAMs reported having less
                  volunteers in 2021 than in 2019; interestingly, the total
                  number of GLAMs with 25 or more volunteers in 2021 also
                  increased, indicating that volunteers have an important role
                  to play in COVID-19 recovery processes.
                </VisualizationNarrative>
                <VolunteerNumbers />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Total Amount of Volunteer Hours
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  In 2019, GLAMs benefited from anywhere between 500-5000
                  volunteer hours at their sites. This dropped significantly in
                  2020, with the majority of GLAMs reporting less than 500
                  volunteer hours, with slight increases reported in 2021.
                </VisualizationNarrative>
                <VolunteerHours />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle>Open to the Public</VisualizationTitle>
            <HeaderSubtitle>
              The ability of GLAMs to be open to the public depends on a variety
              of factors, including (but not limited to) staff and volunteer
              numbers, funding, seasonality, visitor demographics and
              behaviours. Outside of the obvious impacts of COVID-19, the
              majority of Kingston area GLAMs are open to the public between May
              and October. However, a significant number are accessible to the
              public year-round – challenging the perception of Kingston area
              GLAMs as not being a 4-season visitor destination. This is equally
              true in terms of days of the week. While there is a drop in the
              number of GLAMs open each day of the week between 2020 and 2021 –
              what is clear is that residents and visitors can access some sort
              of GLAM on any day of the week.
            </HeaderSubtitle>

            <Grid container spacing={7}>
              <Grid item xs={12} lg={12}>
                <VisualizationTitle>Months Open</VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  The ability of GLAMs to be open to the public depends on a
                  variety of factors, including (but not limited to) staff and
                  volunteer numbers, funding, seasonality, visitor demographics
                  and behaviours.
                </VisualizationNarrative>
                <OpenMonths />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>Days Open</VisualizationTitle>
                <OpenDays />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <DashboardHeader3>Economic Indicators</DashboardHeader3>
          <HeaderSubtitle>
            In 2019 prior to the COVID-19 pandemic, the gross value of Canadian
            GLAMs was estimated at $11.7 billion per year, while their operating
            expenditure was estimated at $3 billion per year. With the gross
            value exceeding operating expenditure, this indicates that for every
            dollar invested in GLAMs, society receives approximately $4 in
            return value (The Value Study of GLAMs in Canada, 2019). GLAMs
            depend on a variety of sources for funding and avenues for revenue
            generation to ensure the integrity of their annual operations and
            long-term strategic goals – this is referred to as revenue
            diversification. The economic data visualized below reveals typical
            ranges of annual operating budgets for Kingston region GLAMs, as
            well as revenue sources and how much these sources contribute to
            overall operating budgets.
          </HeaderSubtitle>

          <Grid container spacing={7} sx={{ mb: 12 }}>
            <Grid item xs={12} lg={12}>
              <VisualizationTitle>
                Total Annual Operating Budget for GLAMs
              </VisualizationTitle>
              <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                As indicated below, GLAM annual operating budgets are seldom
                completely static year-over-year, especially when GLAMs are
                dependent on leveraging funding and support through individual
                grant programs to secure operations. Just as the governance and
                jurisdictional authorities of Kingston region GLAMs range
                considerably, so do the ranges of their annual operating
                budgets. Broadly, Kingston area GLAMs demonstrated operating
                budget growth between 2019 and 2021, with a reduction of sites
                reporting operating budgets under $25K, which can be largely
                attributed to COVID-19 funding relief programs.
              </VisualizationNarrative>
              <OperatingBudget />
            </Grid>

            <Grid item xs={12} lg={12}>
              <VisualizationTitle>Charitable Status</VisualizationTitle>
              <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                Charitable status directly impacts a GLAMs ability to leverage
                revenues and mitigate operating expenditures. To qualify, a GLAM
                must identify its charitable purpose (e.g. advancement of
                education), and provide measurable benefit to the public.
              </VisualizationNarrative>
              <CharitableStatus />
            </Grid>

            <Grid item xs={12} lg={12}>
              <VisualizationTitle>Admission Fees</VisualizationTitle>
              <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                The decision to charge or not to charge admission fee to GLAMs
                depends on a variety of factors such as governance, policy,
                financial, strategic, and even philosophical considerations. The
                majority of GLAMs within the Kingston region do not charge
                admission for site entry.
              </VisualizationNarrative>
              <AdmissionFee />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <VisualizationTitle>
              Sources of Revenue: Government Investment
            </VisualizationTitle>
            <HeaderSubtitle>
              Different levels of government provide different levels and types
              of financial support for GLAMs that operate as public
              institutions. While this type of public investment in cultural
              heritage can provide stable elements of funding, its parameters
              can change as government policies and priorities change.
            </HeaderSubtitle>

            <Grid container spacing={7} sx={{ mb: 5 }}>
              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Federal investment in local GLAMs
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Federal funding for cultural heritage is typically available
                  from grant programs through a variety of federal departments.
                  Some programs are available on a year-on-year basis, others
                  available through special circumstance. It is important to
                  note, that not all GLAMs are eligible to access federal
                  funding, depending on their governance and jurisdictional
                  structures. In 2021, federal funding increased overall for
                  Kingston area GLAMs, due to COVID-19 funding relief
                  initiatives.
                </VisualizationNarrative>
                <FederalRevenue />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Provincial investment in local GLAMs
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Provincial funding for GLAMs can come directly from the
                  Ministry of Tourism, Culture and Sport, or through arms-length
                  organizations with whom the Government of Ontario is
                  affiliated. Some programs are available on a year-on-year
                  basis, others available through special circumstance. It is
                  important to note, that not all GLAMs are eligible to access
                  provincial funding, depending on their governance and
                  jurisdictional structures. Of note, between 2019 and 2021 the
                  majority of Kingston area GLAMs received between 0 and 25% of
                  their funding from provincial sources.
                </VisualizationNarrative>
                <ProvincialRevenue />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Municipal investment in local GLAMs
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Municipal funding for cultural heritage is more variable and
                  less consistent depending on the municipality in question, but
                  when available is typically more accessible to GLAMs.
                  Eligibility can be defined in a variety of ways but is
                  typically limited to those located within the municipality in
                  question. Like their federal and provincial counterparts,
                  funding is generally accessible through specific grant
                  programs on an annual basis, but also occasionally through
                  specific community agreements. For example, the City of
                  Kingston offers both operating and project funding to GLAMs
                  through their City of Kingston Cultural Heritage grant
                  program. As indicated below, municipal investment is the
                  funding most widely accessed by Kingston area GLAMs.
                </VisualizationNarrative>
                <MunicipalRevenue />
              </Grid>

              <Grid item xs={12} lg={12}>
                <VisualizationTitle>
                  Sources of Revenue: Non-Government/Other
                </VisualizationTitle>
                <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                  Even when GLAMs are eligible for and successfully access
                  government funding, this type of investment typically
                  constitutes only a portion of GLAM operation budgets. In 2019,
                  this type of revenue generation was more evenly distributed
                  across budget composition categories, but in 2021, distinctly
                  decreased in its contribution to GLAM operating budgets. Other
                  sources of revenue for Kingston region GLAMs include, but are
                  not limited to: admission fees, visitor donations, tour
                  groups, special events, educational programming, sponsorships,
                  membership fees, direct fundraising, planned giving (donations
                  from estates), investments, private granting agencies.
                </VisualizationNarrative>
                <NonGovernmentRevenue />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <DashboardHeader3>Covid Resiliency Indicators</DashboardHeader3>
          <HeaderSubtitle>
            With the closure of all non-mandatory workplaces in March 2020, and
            evolving health & safety regulatory landscape, GLAMs have had to
            adapt and re-adapt operational practices and engagement approaches
            in order to connect with and provide services to their audiences and
            communities.
          </HeaderSubtitle>

          <VisualizationTitle>Visitor Numbers</VisualizationTitle>
          <HeaderSubtitle>
            The impact of COVID-19 on visitation to Kingston area GLAMs is both
            clear and expected. Onsite visitation dropped dramatically between
            2019 and 2020, with slight recovery in 2021. Online visitation
            (understood as any audience engagement with and through digital
            platforms) increased dramatically between 2019 and 2020, with
            slower, but continued growth in 2021. As COVID-19 recovery processes
            continue, ongoing mapping of visitation trends will be critical to
            evolving resilient cultural heritage provision.
          </HeaderSubtitle>

          <Grid container spacing={7} sx={{ mb: 12 }}>
            <Grid item xs={12} lg={12}>
              <VisualizationTitle>Onsite Visitors</VisualizationTitle>
              <TotalVisitors />
            </Grid>

            <Grid item xs={12} lg={12}>
              <VisualizationTitle>Online Visitors</VisualizationTitle>
              <OnlineVisitors />
            </Grid>

            <Grid item xs={12} lg={12}>
              <VisualizationTitle>
                Public Closures due to COVID
              </VisualizationTitle>
              <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                The majority of Kingston area GLAMs remain open to public
                visitation and engagement throughout the calendar year; however,
                for those who are not, a general, but distinct, pattern emerges
                with site closures during winter and shoulder seasons and
                opening periods between May and September. Beyond the
                provincially mandated closure of all non-essential businesses in
                March and April 2020, many GLAMs, re-opened their sites to
                public visitation in some capacity, partly due to low COVID-19
                transmission rates in the KFL&A health region and partly to
                determined and innovative responses of sites to continue service
                provision. Trends broadly mirrored 2019 with majority of sites
                open to the public May to September, although a clear increase
                in site closures during this period is also evident.
              </VisualizationNarrative>
              <SiteClosures />
            </Grid>

            <Grid item xs={12} lg={12}>
              <VisualizationTitle>
                Audience Engagement Approaches
              </VisualizationTitle>
              <VisualizationNarrative sx={{ mt: 1, mb: 3 }}>
                Throughout 2020 and 2021, Kingston area GLAMs contended with a
                rapidly changing regulatory landscape – at provincial, regional
                and even organizational levels. Understandings of what “open to
                the public” meant also evolved rapidly, with GLAMs redefining
                their public provision in a variety of ways to ensure continuing
                public engagement and relevancy. This chart reveals the
                different approaches Kingston area GLAMs used to connect with
                their audiences and communities. Digital engagement increased
                significantly (as expected), but what is equally interesting
                despite COVID impacts and regulations, many Kingston area GLAMs
                still maintained some degree of onsite visitation capacity –
                including welcoming walk-in visitors.
              </VisualizationNarrative>
              <AudienceEngagement />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default CulturalHeritage;

CulturalHeritage.path = "/dashboards/cultural-heritage";
CulturalHeritage.title = "Cultural Heritage";
CulturalHeritage.description =
  "This dashboard examines cultural heritage across the Kingston region through various indicators before and during the COVID-19 pandemic, and through the recovery process.";
