import * as React from "react";

import { Grid, Typography } from "@mui/material";

import DashboardContainer from "../../layout/DashboardContainer";
import DashboardTitle from "../../typography/DashboardTitle";
import DashboardSubtitle from "../../typography/DashboardSubtitle";
import VisualizationTitle from "../../typography/VisualizationTitle";

import OpenClose from "../../indicators/business_resilience/OpenClose";

import PatioMap from "../../indicators/business_resilience/PatioMap";
import HotelOccupancy from "../../indicators/business_resilience/HotelOccupancy";
import BusinessCounts from "../../indicators/business_resilience/BusinessCounts";
import LocalBusinessConditionIndex from "../../indicators/business_resilience/LocalBusinessConditionIndex";
import CPI from "../../indicators/business_resilience/CPI";
//import BlackOwnedBusinesses from '../../indicators/business_resilience/BlackOwnedBusinesses';
import ValueImports from "../../indicators/business_resilience/ValueImports";
import GDPontariocakst from "../../indicators/business_resilience/GDPontariocakst";
import ValueExports from "../../indicators/business_resilience/ValueExports";

const BusinessResilience = (props) => {
  return (
    <DashboardContainer>
      <DashboardTitle sx={{ mb: 3 }}>{BusinessResilience.title}</DashboardTitle>
      <Typography variant="body1" sx={{ mb: 5 }}>
        This interactive dashboard delves into the impact of the COVID-19
        pandemic on the Kingston economy using indicators such as business
        openings/closures, business counts, hotel occupancy and outdoor patio
        counts. The impacts of COVID-19 are different for each sector and the
        dashboard captures the overview of impact and local solutions such as
        outdoor patios to accommodate social distancing and capacity
        requirements for retailers and food service industries.
      </Typography>
      <Grid container spacing={7} sx={{ mb: 7 }}>
        <Grid item xs={12} lg={12}>
          <VisualizationTitle sx={{ mb: 3 }}>
            Local Business Conditions Index
          </VisualizationTitle>
          {/* <DashboardSubtitle sx={{ mb: 3 }}>Lorem Ipsum.</DashboardSubtitle> */}
          <LocalBusinessConditionIndex />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Consumer Price Index</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            Ontario data, not seasonally adjusted.
          </DashboardSubtitle>
          <CPI />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            GDP Of Kingston, Ontario And Canada
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <GDPontariocakst />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Value Of Imports</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <ValueImports />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Value Of Exports</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}></DashboardSubtitle>
          <ValueExports />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>State of Businesses</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            The interactive table provides data on business opening and closing
            (based on sectors) which can be compared to provincial and national
            data. Kingston data is consistent with provincial and national
            trends with a significant increase of business closures in March
            2020 when COVID-19 was declared a global pandemic.
          </DashboardSubtitle>
          <OpenClose />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Business Count</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 2 }}>
            This interactive business count graph identifies the top 10
            industries in Kingston and Ontario which can be further analyzed by
            number of employees data. The graph offers insight into the summary
            of business sectors and the respective percentage in Kingston and
            Ontario.
          </DashboardSubtitle>
          <BusinessCounts />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Hotel Industry</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 2 }}>
            These hotel industry graphs represent occupancy rate, average daily
            rate and revenue rate per available room for the Province of
            Ontario. The occupancy rate is the ratio of rented rooms compared to
            the total amount of available rooms. The occupancy decreased at the
            onset of COVID-19 and continued into 2021 as travel and business
            restrictions remained in place during this period.
          </DashboardSubtitle>
          <HotelOccupancy />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Business Patios</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 2 }}>
            The map provides locations of patio permits for 2020 and 2021.
            Patios in the City of Kingston were an important part of the
            strategy to support commercial businesses when COVID-19 restrictions
            were still in place and social distancing requirements decreased
            normal capacities. The City of Kingston worked to fast-track
            temporary patio permits on private and public lands. There was an
            increase in patios especially in the downtown as many of the
            expanded patios occupied parking spaces adjacent to participating
            businesses.
          </DashboardSubtitle>
          <PatioMap />
        </Grid>

        {/* <Grid item xs={12} lg={12}>
          <VisualizationTitle>Black Owned Businesses</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 2 }}>Lorem ipsum, dolor sit amet consectetur adipisicing elit</DashboardSubtitle>
          <BlackOwnedBusinesses />
        </Grid> */}
      </Grid>
    </DashboardContainer>
  );
};

export default BusinessResilience;

BusinessResilience.path = "/dashboards/local-economy";
BusinessResilience.title = "Local Economy";
BusinessResilience.description =
  "This dashboard explores indicators for local economic development and examines the impact of the COVID-19 pandemic on businesses in Kingston.";
