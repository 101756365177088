import React from "react";
import { Snackbar, Skeleton, Grid } from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";
import { Bar } from "react-chartjs-2";
import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const getChartData = (contractEmpNumbersData) => {
  let ranges = getUniqueRanges(contractEmpNumbersData);
  let years = Object.keys(contractEmpNumbersData.data[0]).sort();

  let colourInd = 0;
  let chromaPalette = palette(ranges.length);

  let datasets = ranges.map((range) => {
    let data = years.map((year) => {
      let datapoints = contractEmpNumbersData.data
        .filter((obj) => obj[year] === range)
        .map((obj) => obj[year]);

      return {
        x: datapoints.length,
        y: year,
      };
    });

    let dataset = {
      label: range,
      backgroundColor: chromaPalette[colourInd],
      borderColor: chromaPalette[colourInd],
      data: data,
      grouped: true,
    };

    colourInd += 1;
    return dataset;
  });

  return {
    datasets: datasets,
  };
};

const options = {
  indexAxis: "y",
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    x: {
      stacked: false,
      title: {
        text: "Number of GLAMs with range of contract employees",
        display: true,
      },
    },
    y: {
      stacked: false,
      title: {
        text: "Year",
        display: true,
      },
    },
  },
};

function getUniqueRanges(dataset) {
  let allRanges = [];
  let years = Object.keys(dataset.data[0]).sort();

  for (const year of years) {
    allRanges = allRanges.concat(
      dataset.data.map((obj) => obj[year]).filter((obj) => parseInt(obj)),
    );
  }

  return allRanges.filter(onlyUnique).sort((a, b) => parseInt(a) - parseInt(b));
}

const ContractEmpNumbers = () => {
  const sourceCode = "get_contract_emp_numbers";
  const {
    data: contractEmpNumbersData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {contractEmpNumbersData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData({ ...contractEmpNumbersData[0] })}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default ContractEmpNumbers;
