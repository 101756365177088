import * as React from "react";
import Typography from "@mui/material/Typography";
import DashboardHeader5 from "../typography/DashboardHeader5";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const SongInfo = [
  {
    title: "Lost Boys: An Ellegy for Humboldt - The Wawa Project",
    description:
      "John Valberg, known as The Wawa Project, is a songwriter and piano guy. Most of his songs reflect feelings about life, grandkids and aging. His music includes easy listening instrumental tracks, pop, and fun songs.",
  },
  {
    title: "Fool In – Room Tone",
    description:
      "Emerging artists and composers Helena Hannibal and Eric Liu have teamed up to form Room Tone Jazz, an expressive jazz duo which takes influences from traditional standards to r&b and pop music in order to develop their unique laid back sound. Born as a small busking project on the streets of Kingston during the summer of 2021, the group has since developed into a minimalist approach to jazz expression and continues to perform live throughout Kingston and the surrounding areas. The duo recently recorded their debut album “In Side” live from the Isabel featuring Helena on alto and tenor saxophone and Eric on guitar. This album is available on all streaming platforms and features the group’s latest original compositions of traditional jazz and modern influences to create a fresh sound of its own.",
  },
  {
    title: "Gratitude – Ian Wong",
    description:
      "Ian Wong is a modern classical piano composer and recording artist based out of Kingston. Since 2017, his instrumental music has been streamed millions of times on streaming platforms, and his tracks have been featured on notable editorial playlists on Spotify, Apple Music, Amazon Music, Pandora, and YouTube Music. He primarily releases music through the New York-based label Sonder House. He received his A.R.C.T. diploma in piano performance at a young age and received formal training in composition from Dr. John Burge at Queen's University.",
  },
  {
    title: "Flatwoods – Shino",
    description:
      "A Kingston-based solo artist who releases music under the stage character 'Shino,' a spacefaring robotic being. Their music is based in the synth and guitar sounds of the 80s mixed with modern influences such as EDM and future funk.",
  },
  {
    title: "Strathspey – Danielle Lennon",
    description:
      "Danielle is a classically trained violinist who graduated with a Bachelor of Music from Queen's University. She has been a member of The Kingston Symphony for over twenty years and has also played with many local bands, affording her the opportunity to play a variety of styles including fiddle, folk, rock and blues. She is frequently called upon to do studio work and has recorded with The Glorious Sons, Kasador, Kris and Dee, Megan Hamilton, The Swamp Ward Orchestra, Chris Koster, The Gertrudes and many others.",
  },
  {
    title: "Bitter Tart – Greg Runions Group",
    description:
      "Greg Runions is a Canadian composer, arranger and percussionist residing in Kingston, Ontario. His original works for big band, small groups, orchestra, orchestra with big band, and percussion ensemble have been performed by The Greg Runions Big Band, Greg Runions Septet, Kingston Symphony Orchestra and by ensembles at colleges and high schools in Canada, Italy, U.S.A. and the Netherlands.",
  },
  {
    title: "Obliteration – David Parker ",
    description:
      "David Parker is an artist and musician playing music in projects that are experimental, drone, and improvised, collaborating in ensembles and performing solo improvised work. He writes, records and performs singer-songwriter material and was previously known as Slow Man Tofu.",
  },
  {
    title: "3AM – Mary Bryton ",
    description:
      "Mary Bryton Nahwegahbow is an aspiring First Nations singer, songwriter, and musician. Mary is a graduate from the Vocal Arts Program at Canterbury High School in Ottawa and the Royal Conservatory of Music level 8 Voice and Music Theory. She is a multi-talented artist who sings as a soloist and in a choir, plays drums and guitar. She is currently studying in the Bachelor of Music and Concurrent Education programs at the Queen's University DAN School of Drama & Music.",
  },
  {
    title: "Prelude No. 2 ''Summer Night: A Tango'' - John Robertson",
    description:
      "John is a composer of 'concert' who has lived in Kingston since 2007. His work has been heard worldwide. This year he has work being played in the US, UK, and a large project in Bulgaria where a stage piece of his will be performed as part of an international Ballet Conductors' masterclass. The present piece ''Summer Night - a Tango'' along with three other pieces of his will be part of an album titled ''Piano Spectrums'' to be issued by Navona records in April 2022. The pianist Anna Kislitsyna will perform all four pieces as part of her recital in the Weill Recital Hall, part of Carnegie Hall, in New York City in June 2022.",
  },
];

export default function MusicOnHoldDetails(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <Typography>Playlist Description </Typography>
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>{"YGK Music 2022 - Music on Hold"}</DialogTitle>
        <DialogContent>
          {SongInfo.map((datum) => {
            if (datum.title === null) return "";
            return (
              <>
                <DashboardHeader5>{datum.title}</DashboardHeader5>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  {datum.description}
                </Typography>
              </>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
