import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  ListItemText,
  Grid,
  RadioGroup,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Bar } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const getUngrouped = (groupBy) => {
  let ungroupedSet = "";

  if (groupBy === "Location") {
    ungroupedSet = [
      { element: "Age group", defaultValue: "Total, 12 years and over" },
      { element: "Sex", defaultValue: "Both sexes" },
    ];
  } else if (groupBy === "Age group") {
    ungroupedSet = [
      { element: "Location", defaultValue: "Kingston" },
      { element: "Sex", defaultValue: "Both sexes" },
    ];
  } else {
    ungroupedSet = [
      { element: "Location", defaultValue: "Kingston" },
      { element: "Age group", defaultValue: "Total, 12 years and over" },
    ];
  }

  let firstUngrouped = ungroupedSet[0].element;
  let firstDefaultValue = ungroupedSet[0].defaultValue;

  let secondUngrouped = ungroupedSet[1].element;
  let secondDefaultValue = ungroupedSet[1].defaultValue;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label={firstDefaultValue}
            helperText={firstUngrouped}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-basic"
            label={secondDefaultValue}
            helperText={secondUngrouped}
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};

const getChartData = (
  healthCharacteristicData,
  groupBy,
  descriptor,
  characteristic,
  measure,
) => {
  let colourInd = 0;
  let ungrouped = "";

  let numColours = getUniqueDescriptor(
    healthCharacteristicData.data,
    groupBy,
  ).length;

  let chromaPalette = palette(numColours);

  if (groupBy === "Location") {
    ungrouped = [
      { element: "Age group", defaultValue: "Total, 12 years and over" },
      { element: "Sex", defaultValue: "Both sexes" },
    ];
  } else if (groupBy === "Age group") {
    ungrouped = [
      { element: "Location", defaultValue: "Kingston" },
      { element: "Sex", defaultValue: "Both sexes" },
    ];
  } else {
    ungrouped = [
      { element: "Location", defaultValue: "Kingston" },
      { element: "Age group", defaultValue: "Total, 12 years and over" },
    ];
  }

  let firstUngrouped = ungrouped[0].element;
  let firstDefaultValue = ungrouped[0].defaultValue;

  let secondUngrouped = ungrouped[1].element;
  let secondDefaultValue = ungrouped[1].defaultValue;

  let datasets = descriptor.map((descriptorValue) => {
    let data = healthCharacteristicData.data
      .filter((obj) => obj["Characteristics"] === characteristic)
      .filter((obj) => obj["Measure"] === measure)
      .filter((obj) => obj[groupBy] === descriptorValue)
      .filter((obj) => obj[firstUngrouped] === firstDefaultValue)
      .filter((obj) => obj[secondUngrouped] === secondDefaultValue)
      .map((obj) => ({ x: obj["REF_DATE"], y: obj["VALUE"] }));

    let dataset = {
      label: descriptorValue,
      data: data,
    };

    return dataset;
  });

  // sorting the datasets
  // 1- drawing longer datasets first (to avoid technical issues, but these should all be the same length)
  // 2- drawing datasets in alphabetical order by label
  datasets.sort(function (a, b) {
    if (a.data.length < b.data.length) {
      return -1;
    } else if (a.data.length > b.data.length) {
      return 1;
    } else {
      if (a.label <= b.label) {
        return -1;
      } else {
        return 1;
      }
    }
  });

  // setting the colours for each dataset AFTER we have sorted them
  datasets.forEach(function (item) {
    item["backgroundColor"] = chromaPalette[colourInd];
    item["borderColor"] = chromaPalette[colourInd];
    colourInd += 1;
  });

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    x: {
      title: {
        text: "Year",
        display: true,
      },
      type: "category",
      labels: ["2015/2016", "2017/2018", "2019/2020"],
    },
    y: {
      title: {
        text: "Number of people",
        display: true,
      },
    },
  },
};

function getUniqueGroupBy() {
  return ["Location", "Sex", "Age group"];
}

function getUniqueDescriptor(data, groupBy) {
  if (groupBy === "Sex") {
    return data
      .map((obj) => obj[groupBy])
      .filter(onlyUnique)
      .filter((obj) => obj !== "Both sexes"); // currently ignoring "Both sexes" as it doesn't really add information
  } else if (groupBy === "Location") {
    return data.map((obj) => obj[groupBy]).filter(onlyUnique);
  } else {
    return data.map((obj) => obj["Age group"]).filter(onlyUnique);
  }
}

function getUniqueCharacteristic(data) {
  return data.map((obj) => obj["Characteristics"]).filter(onlyUnique);
}

const HealthCharacteristic = (props) => {
  const sourceCode = "get_health_characteristics";
  const {
    data: healthCharacteristicData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  const [groupBy, setGroupBy] = React.useState("Age group");
  const [uniqueGroupBy, setUniqueGroupBy] = React.useState([]);

  const [descriptor, setDescriptor] = React.useState([
    "12 to 17 years",
    "18 to 34 years",
    "35 to 49 years",
    "50 to 64 years",
    "65 years and over",
    "Total, 12 years and over",
  ]);
  const [uniqueDescriptor, setUniqueDescriptor] = React.useState([]);

  const [characteristic, setCharacteristic] = React.useState(
    "Perceived mental health, fair or poor",
  );
  const [uniqueCharacteristic, setUniqueCharacteristic] = React.useState([]);

  const [measure, setMeasure] = React.useState("Number of people");

  React.useEffect(() => {
    if (healthCharacteristicData) {
      setUniqueGroupBy(getUniqueGroupBy());
    }
  }, [healthCharacteristicData]);

  React.useEffect(() => {
    if (healthCharacteristicData) {
      let descriptors = getUniqueDescriptor(
        healthCharacteristicData[0].data,
        groupBy,
      );
      setUniqueDescriptor(descriptors);
      setDescriptor(descriptors);
    }
  }, [healthCharacteristicData, groupBy]);

  React.useEffect(() => {
    if (healthCharacteristicData) {
      setUniqueCharacteristic(
        getUniqueCharacteristic(healthCharacteristicData[0].data),
      );
    }
  }, [healthCharacteristicData]);

  const handleGroupByChange = (event) => {
    const {
      target: { value },
    } = event;

    setGroupBy(value);
  };

  const handleDescriptorChange = (event) => {
    const {
      target: { value },
    } = event;
    setDescriptor(typeof value === "string" ? value.split(",") : value);
  };

  const handleCharacteristicChange = (event) => {
    const {
      target: { value },
    } = event;

    setCharacteristic(value);
  };

  const handleMeasureChange = (event) => {
    const {
      target: { value },
    } = event;

    options.scales.y.title.text = event.target.value;

    setMeasure(value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {healthCharacteristicData && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="group-by-simple-select-label">
                  Group By...
                </InputLabel>
                <Select
                  labelId="group-by-simple-select-label"
                  id="group-by-simple-select"
                  value={groupBy}
                  label="Group By..."
                  onChange={handleGroupByChange}
                >
                  {uniqueGroupBy.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="descriptor-multiple-checkbox-label">
                  Descriptor
                </InputLabel>
                <Select
                  labelId="descriptor-multiple-checkbox-label"
                  id="descriptor-multiple-checkbox"
                  multiple
                  value={descriptor}
                  onChange={handleDescriptorChange}
                  input={<OutlinedInput label="Descriptor" />}
                  renderValue={(selected) => selected.sort().join(", ")}
                >
                  {uniqueDescriptor.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={descriptor.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="characteristic-simple-select-label">
                  Characteristic
                </InputLabel>
                <Select
                  labelId="characteristic-simple-select-label"
                  id="characteristic-simple-select"
                  value={characteristic}
                  label="Characteristic"
                  onChange={handleCharacteristicChange}
                >
                  {uniqueCharacteristic.sort().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2}>
              <FormControl hiddenLabel={true}>
                <RadioGroup
                  aria-label={
                    <FormLabel id="format-radio-buttons-group">
                      Measure
                    </FormLabel>
                  }
                  name="format-radio-buttons-group"
                  value={measure}
                  onChange={handleMeasureChange}
                >
                  <FormControlLabel
                    value="Number of people"
                    control={<Radio size="small" sx={{ padding: "6px" }} />}
                    label="Count"
                  />
                  <FormControlLabel
                    value="Percent"
                    control={<Radio size="small" sx={{ padding: "6px" }} />}
                    label="Percent"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              {getUngrouped(groupBy)}
            </Grid>
            <Grid item xs={12} lg={12}>
              <Bar
                data={getChartData(
                  { ...healthCharacteristicData[0] },
                  groupBy,
                  descriptor,
                  characteristic,
                  measure,
                )}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default HealthCharacteristic;

HealthCharacteristic.defaultProps = {};
