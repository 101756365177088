import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Alert } from "@mui/material";

import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Pie } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import onlyUnique from "../../../helpers/dataManipulation";
import palette from "../../../helpers/palette";

const getChartAndLegend = (data, geo) => {
  let currentOptions = {};

  // getting labels & options for the right geography
  let labels = data[geo].map((obj) => obj["Sector (NAICS)"]);

  if (geo === "Kingston") {
    optionsK.plugins.title.text = geo;
    currentOptions = optionsK;
  } else {
    optionsO.plugins.title.text = geo;
    currentOptions = optionsO;
  }

  // getting the palette colours which correspond with this chart's labels
  let labelledPalette = generatePalette(data);
  let colours = [];

  labels.forEach((label, index) => {
    colours.push(
      labelledPalette
        .filter((obj) => obj.label === label)
        .map((obj) => obj.colour)[0],
    );
  });

  // getting the data ready for the chart
  let result = {
    labels: labels,
    datasets: [
      {
        label: geo,
        backgroundColor: colours,
        data: data[geo].map((obj) => obj["VALUE"]),
      },
    ],
  };

  // getting the sum of the values for this geography
  // (will help calculate percentage of pie later)
  const sum = data[geo].reduce((accumulator, obj) => {
    return accumulator + obj["VALUE"];
  }, 0);

  // preparing legend rows (colour + corresponding label)
  const rows = [];

  colours.forEach((colour, index) => {
    rows.push({
      colour: colour,
      code: data[geo]
        .filter((obj) => obj["Sector (NAICS)"] === labels[index])
        .map((obj) => obj["Code (NAICS)"])[0],
      sector: labels[index],
      count: result.datasets[0].data[index]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","), // adds comma separators for thousandths
      percent: ((result.datasets[0].data[index] / sum) * 100).toFixed(1),
    });
  });

  // generating the chart & its legend
  return (
    <>
      <Grid item xs={12} lg={5}>
        <Pie data={result} options={currentOptions} />
      </Grid>
      <Grid item xs={10} lg={7}>
        <TableContainer>
          <Table
            aria-label={geo + " Legend"}
            sx={{ borderSpacing: 2, borderCollapse: "separate" }}
          >
            {getLegendHeader()}
            {getLegendBody(rows)}
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

const optionsK = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      text: "TEST",
      display: true,
      font: {
        size: 16,
      },
    },
  },
};

const optionsO = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      text: "TEST",
      display: true,
      font: {
        size: 16,
      },
    },
  },
};

function getLegendHeader() {
  // the styling gets angry if we don't generate a header so it exists but it's hidden
  // <TableHead sx={{ display: 'none' }}>
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            lineHeight: 1.2,
            paddingRight: 1,
            paddingLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          }}
        >
          Colour
        </TableCell>
        <TableCell
          sx={{
            lineHeight: 1.2,
            paddingRight: 1,
            paddingLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          }}
        >
          Code (NAICS)
        </TableCell>
        <TableCell
          sx={{
            lineHeight: 1.2,
            paddingRight: 1,
            paddingLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          }}
        >
          Sector
        </TableCell>
        <TableCell
          sx={{
            lineHeight: 1.2,
            paddingRight: 1,
            paddingLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          }}
        >
          Count
        </TableCell>
        <TableCell
          sx={{
            lineHeight: 1.2,
            paddingRight: 1,
            paddingLeft: 1,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "right",
          }}
        >
          % of chart
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function getLegendBody(rows) {
  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.sector}>
          <TableCell sx={{ backgroundColor: row.colour }}></TableCell>
          <TableCell sx={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
            {row.code}
          </TableCell>
          <TableCell sx={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
            {row.sector}
          </TableCell>
          <TableCell sx={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
            {row.count}
          </TableCell>
          <TableCell
            sx={{
              paddingRight: 1,
              paddingTop: 0,
              paddingBottom: 0,
              textAlign: "right",
            }}
          >
            {row.percent}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

function getUniqueEmployees(data1, data2) {
  let unique1 = data1.map((obj) => obj["Employment size"]).filter(onlyUnique);

  let unique2 = data2.map((obj) => obj["Employment size"]).filter(onlyUnique);

  return Object.values({ ...unique1, ...unique2 });
}

function filterData(
  kingstonData,
  ontarioData,
  employees,
  numIndustries,
  codeDigits,
) {
  // checking for usual case (ex. "21") and special cases (ex. "22-23")
  let regex1 = new RegExp("^\\d{" + codeDigits + "}$", "g");
  let regex2 = new RegExp(
    "^\\d{" + codeDigits + "}-\\d{" + codeDigits + "}$",
    "g",
  );

  let trimmedKingstonData = kingstonData.data
    .filter((obj) => obj["Employment size"] === employees)
    .filter((obj) => {
      return (
        obj["Code (NAICS)"].match(regex1) || obj["Code (NAICS)"].match(regex2)
      );
    })
    .sort((a, b) => {
      return b["VALUE"] - a["VALUE"];
    })
    .slice(0, numIndustries);

  let trimmedOntarioData = ontarioData.data
    .filter((obj) => obj["Employment size"] === employees)
    .filter((obj) => {
      return (
        obj["Code (NAICS)"].match(regex1) || obj["Code (NAICS)"].match(regex2)
      );
    })
    .sort((a, b) => {
      return b["VALUE"] - a["VALUE"];
    })
    .slice(0, numIndustries);

  return {
    Kingston: trimmedKingstonData,
    Ontario: trimmedOntarioData,
  };
}

function generatePalette(data) {
  let uniqueLabels = [...data.Kingston, ...data.Ontario]
    .map((obj) => obj["Sector (NAICS)"])
    //.sort((a, b) => a.localeCompare(b))
    .filter(onlyUnique);

  let rawPalette = palette(uniqueLabels.length);
  let labelledPalette = [];

  rawPalette.forEach((colour, index) => {
    labelledPalette.push({
      label: uniqueLabels[index],
      colour: colour,
    });
  });

  return labelledPalette;
}

const BusinessCounts = (props) => {
  const sourceCodeKingston = "get_business_counts_kingston";
  const {
    data: businessCountDataKingston,
    isFetching: isFetchingK,
    isError: isErrorK,
  } = useRecord(sourceCodeKingston, null);

  const sourceCodeOntario = "get_business_counts_ontario";
  const {
    data: businessCountDataOntario,
    isFetching: isFetchingO,
    isError: isErrorO,
  } = useRecord(sourceCodeOntario, null);

  const [employees, setEmployees] = React.useState("Total, with employees");
  const [uniqueEmployees, setUniqueEmployees] = React.useState([]);

  React.useEffect(() => {
    if (businessCountDataKingston && businessCountDataOntario) {
      setUniqueEmployees(
        getUniqueEmployees(
          businessCountDataKingston[0].data,
          businessCountDataOntario[0].data,
        ),
      );
    }
  }, [businessCountDataKingston, businessCountDataOntario]);

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;

    setEmployees(value);
  };

  return (
    <>
      {isErrorK && isErrorO && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetchingK && isFetchingO && (
        <Skeleton variant="rectangular" height={200} />
      )}
      {businessCountDataKingston && businessCountDataOntario && (
        <>
          <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
            <Grid item xs={12} lg={12} sx={{ mb: 2 }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="employees-simple-select-label">
                  Number of Employees
                </InputLabel>
                <Select
                  labelId="category-simple-select-label"
                  id="category-simple-select"
                  value={employees}
                  label="Number of Employees"
                  onChange={handleCategoryChange}
                >
                  {uniqueEmployees.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid
                container
                spacing={4}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingTop: 2,
                }}
              >
                {getChartAndLegend(
                  filterData(
                    { ...businessCountDataKingston[0] },
                    { ...businessCountDataOntario[0] },
                    employees,
                    props.numIndustries,
                    props.codeDigits,
                  ),
                  "Kingston",
                )}
              </Grid>
              <Grid
                container
                sx={{ justifyContent: "flex-end", alignItems: "right" }}
              >
                <Grid item>
                  <DataSourceRef code={sourceCodeKingston} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid
                container
                spacing={4}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingTop: 4,
                }}
              >
                {getChartAndLegend(
                  filterData(
                    { ...businessCountDataKingston[0] },
                    { ...businessCountDataOntario[0] },
                    employees,
                    props.numIndustries,
                    props.codeDigits,
                  ),
                  "Ontario",
                )}
              </Grid>
              <Grid
                container
                sx={{ justifyContent: "flex-end", alignItems: "right" }}
              >
                <Grid item>
                  <DataSourceRef code={sourceCodeKingston} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BusinessCounts;

BusinessCounts.defaultProps = {
  numIndustries: 10,
  codeDigits: 2,
};
