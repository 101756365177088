import React from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { useState } from "react";

import {
  Alert,
  Box,
  Skeleton,
  Snackbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import markerImage from "../../../static/icons/map_marker_red.png";

import { useRecord } from "../../../api/records";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

const formatMarkerData = (farmlocationsdata) => {
  let mapableData = farmlocationsdata.filter((obj) => obj["Mapable"] === true);

  let formatted = mapableData.map((marker) => {
    return {
      Latitude: parseFloat(marker["Latitude"]),
      Longitude: parseFloat(marker["Longitude"]),
      Title: marker["Farm"],
      Label: marker["Address"],
      Website: marker["Website"],
    };
  });

  return formatted;
};

const getFarmList = (farmlocationsdata) => {
  let unmapableData = farmlocationsdata.data.filter(
    (obj) => obj["Mapable"] === false,
  );

  let farmName = "";
  let farmWebsite = "";

  let farmArray = [];

  for (let i = 0; i < unmapableData.length; i += 1) {
    farmName = unmapableData[i]["Farm"];
    farmWebsite = unmapableData[i]["Website"];

    if (farmWebsite == null) {
      farmWebsite = "Website not available";
    }

    if (farmName != null) {
      farmArray.push({ farmName: farmName, farmWebsite: farmWebsite });
    }
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Other Food Producers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Website</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {farmArray.map((farm) => (
                  <TableRow
                    key={farm.farmName}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {farm.farmName}
                    </TableCell>
                    <TableCell align="right">
                      <a
                        href={farm.farmWebsite}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {farm.farmWebsite}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const FarmsMap = () => {
  const sourceCode = "get_farm_locations";
  const {
    data: farmlocationsdata,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  const mapStyles = {
    height: "80vh",
    width: "100%",
  };

  const mapCenter = {
    lat: 44.309058,
    lng: -78.319747,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
  });

  const initInfoOpen = () => {
    let initState = {};
    const range = [...Array(50).keys()];
    range.forEach((index) => {
      initState[index] = false;
    });
    return initState;
  };
  const [infoOpen, setInfoOpen] = useState(initInfoOpen());

  const handleInfoOpen = (index) => {
    let updatedInfoOpen = { ...infoOpen };

    updatedInfoOpen[index] = !infoOpen[index];

    setInfoOpen(updatedInfoOpen);
  };

  return isLoaded ? (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={500} />}
      {farmlocationsdata && (
        <>
          <Box
            sx={{
              mb: 1,
              display: "flex",
              justifyContent: "center",
            }}
          ></Box>
          <GoogleMap mapContainerStyle={mapStyles} zoom={7} center={mapCenter}>
            {formatMarkerData(farmlocationsdata[0].data).map(
              (element, index) => {
                let marker = new window.google.maps.MarkerImage(
                  markerImage,
                  null,
                  null,
                  null,
                );
                return (
                  <Marker
                    icon={marker}
                    key={index}
                    position={{ lat: element.Latitude, lng: element.Longitude }}
                    onClick={() => {
                      handleInfoOpen(index);
                    }}
                  >
                    {infoOpen[index] && (
                      <InfoWindow
                        onCloseClick={() => {
                          handleInfoOpen(index);
                        }}
                        position={{
                          lat: element.latitude,
                          lng: element.longitude,
                        }}
                      >
                        <div>
                          <h3>{element.Title}</h3>
                          <h5>{element.Label}</h5>
                          <a
                            href={element.Website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {element.Website}
                          </a>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              },
            )}
          </GoogleMap>
          {getFarmList({ ...farmlocationsdata[0] })}
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default FarmsMap;
