import React from "react";

import { Line } from "react-chartjs-2";

import { Snackbar, Skeleton, Grid, Alert } from "@mui/material";

import { useRecord } from "../../../api/records";

import DataSourceRef from "../../common/footnotes/DataSourceRef";
import palette from "../../../helpers/palette";

const getChartData = (waitlistData) => {
  let chromaPalette = palette(1);

  let data_points = waitlistData.data
    .filter((obj) => obj["Year"] >= 2012)
    .map(function (obj) {
      return {
        x: obj["Year"] + "-" + obj["Month"],
        y: obj["Eligible Applicants"],
      };
    });

  let set = {
    label: "Number of Eligible Applicants",
    backgroundColor: chromaPalette[0],
    borderColor: chromaPalette[0],
    data: data_points,
  };

  return {
    datasets: [set],
  };
};

const options = {
  spanGaps: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        parser: "YYYY-MM",
        tooltipFormat: "MMM YYYY",
        unit: "month",
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 30,
      },
    },
    y: {
      title: {
        text: "Number of Eligible Applicants",
        display: true,
      },
    },
  },
};

const HousingWaitlist = () => {
  const sourceCode = "get_housing_waitlist";
  const {
    data: waitlistData,
    isFetching,
    isError,
  } = useRecord(sourceCode, null);

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {waitlistData && (
        <>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={12}>
              <Line
                data={getChartData({ ...waitlistData[0] })}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourceCode} />
        </>
      )}
    </>
  );
};

export default HousingWaitlist;
