import * as React from "react";

import { Grid, Button, Box, Typography } from "@mui/material";

import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import MediaCard from "../cards/MediaCard";

import Employment from "../pages/dashboards/Employment";
import BusinessResilience from "../pages/dashboards/BusinessResilience";
import CommunityVulnerabilities from "../pages/dashboards/CommunityVulnerabilities";
import Mobility from "../pages/dashboards/Mobility";
import EnvironmentSustainability from "../pages/dashboards/EnvironmentSustainability";
import Housing from "../pages/dashboards/Housing";
import CulturalHeritage from "../pages/dashboards/CulturalHeritage";
import Demographics from "../pages/dashboards/Demographics";

import imgBusiness from "../../static/img/business_resilience/bridal store - pivot to face masks.jpg";
import imgEmployment from "../../static/img/Employment_Bazely_cropped_IMG_20210928_140541040.jpg";
import imgCommunityVulnerabilities from "../../static/img/community/community_card.jpg";
import imgMobility from "../../static/img/mobility/mobility_card.jpg";
import imgEnvironmentSustainability from "../../static/img/Environment 1_DeLoyde_Dashboard11.JPG";
import imgHousing from "../../static/img/housing/housing_card.jpg";
import imgCulturalHeritage from "../../static/img/cultural_heritage/sign of museums.png";
import imgDemographics from "../../static/img/demographics/demographics_card.jpg";
import CommunitySafety from "../pages/dashboards/CommunitySafety";

const dashCardInfo = [
  {
    MediaCard: {
      title: BusinessResilience.title,
      description: BusinessResilience.description,
      CardBackground: {
        backgroundImage: `url("${imgBusiness}")`,
      },
    },
    href: BusinessResilience.path,
  },
  {
    MediaCard: {
      title: Employment.title,
      description: Employment.description,
      CardBackground: {
        backgroundImage: `url("${imgEmployment}")`,
      },
    },
    href: Employment.path,
  },
  {
    MediaCard: {
      title: CommunityVulnerabilities.title,
      description: CommunityVulnerabilities.description,
      CardBackground: {
        backgroundImage: `url("${imgCommunityVulnerabilities}")`,
      },
    },
    href: CommunityVulnerabilities.path,
  },
  {
    MediaCard: {
      title: Mobility.title,
      description: Mobility.description,
      CardBackground: {
        backgroundImage: `url("${imgMobility}")`,
      },
    },
    href: Mobility.path,
  },
  {
    MediaCard: {
      title: EnvironmentSustainability.title,
      description: EnvironmentSustainability.description,
      CardBackground: {
        backgroundImage: `url("${imgEnvironmentSustainability}")`,
      },
    },
    href: EnvironmentSustainability.path,
  },
  {
    MediaCard: {
      title: Housing.title,
      description: Housing.description,
      CardBackground: {
        backgroundImage: `url("${imgHousing}")`,
      },
    },
    href: Housing.path,
  },
  {
    MediaCard: {
      title: CulturalHeritage.title,
      description: CulturalHeritage.description,
      CardBackground: {
        backgroundImage: `url("${imgCulturalHeritage}")`,
      },
    },
    href: CulturalHeritage.path,
  },
  {
    MediaCard: {
      title: Demographics.title,
      description: Demographics.description,
      CardBackground: {
        backgroundImage: `url("${imgDemographics}")`,
      },
    },
    href: Demographics.path,
  },
  {
    MediaCard: {
      title: CommunitySafety.title,
      description: CommunitySafety.description,
      // CardBackground: {
      //   backgroundImage: `url("${imgDemographics}")`,
      // },
    },
    href: CommunitySafety.path,
  },
];

function getQSpaceUrl(dashboardTitle) {
  return `https://qspace.library.queensu.ca/search?scope=28264e28-1843-437c-abca-776a363a1c1c&query=City+of+Kingston+${dashboardTitle}`;
}

const DashboardCards = ({ showDescription }) => {
  return (
    <>
      {showDescription && (
        <Typography variant="body1" sx={{ mb: 3 }}>
          These {dashCardInfo.length} dashboards point to priority areas of
          impact in the city from COVID-19 and identify indicators to help us
          measure municipal resiliency. The dashboards contain both real-time
          and static data of pre and post pandemic changes. Explore each
          dashboard to discover and interact with the data.
        </Typography>
      )}
      <Grid container spacing={3}>
        {dashCardInfo.map((object, i) => (
          <Grid item xs={12} sm={6} lg={4} key={i}>
            <MediaCard {...object.MediaCard}>
              <Box sx={{ display: "block", pl: 2, pb: 2, pr: 2 }}>
                <Button
                  variant="outlined"
                  endIcon={<DoubleArrowIcon />}
                  href={object.href}
                >
                  View
                </Button>
                <Button
                  sx={{ mt: 1, pl: 0 }}
                  size="small"
                  variant="text"
                  endIcon={<OpenInNewIcon />}
                  href={getQSpaceUrl(object.MediaCard.title)}
                  target="_blank"
                >
                  Search theses and dissertations
                </Button>
              </Box>
            </MediaCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DashboardCards;

DashboardCards.defaultProps = {
  showDescription: true,
};
