import React from "react";

import {
  Snackbar,
  Skeleton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { Alert } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

import { Line } from "react-chartjs-2";

import { useRecord } from "../../../api/records";

import palette from "../../../helpers/palette";
import ErrorCard from "../../common/ErrorCard";

const categories = [
  "All-items",
  "Food",
  "Shelter",
  "Household operations, furnishings and equipment",
  "Clothing and footwear",
  "Transportation",
  "Gasoline",
  "Health and personal care",
  "Recreation, education and reading",
  "Alcoholic beverages, tobacco products and recreational cannabis",
  "All-items excluding food and energy",
  "All-items excluding energy",
  "Energy",
  "Goods",
  "Services",
];

const getChartData = (cpiData, selCategories) => {
  let colourInd = 0;

  let chromaPalette = palette(selCategories.length);

  let datasets = selCategories.map((categoryValue) => {
    let data_point = cpiData.data
      .filter((obj) => obj["Products and product groups"] === categoryValue)
      .map((obj) => ({
        x: obj["REF_DATE"],
        y: obj["VALUE"],
      }));

    // transparent points
    let pointColor = "rgba(0, 0, 0, 0)";

    let set = {
      label: categoryValue,
      pointBackgroundColor: pointColor,
      pointBorderColor: pointColor,
      data: data_point,
    };

    return set;
  });

  // sorting the datasets
  datasets.sort(function (a, b) {
    return categories.indexOf(a.label) - categories.indexOf(b.label);
  });

  // setting the colours for each dataset AFTER we have sorted them
  datasets.forEach(function (item) {
    item["backgroundColor"] = chromaPalette[colourInd];
    item["borderColor"] = chromaPalette[colourInd];
    item["pointHoverBackgroundColor"] = chromaPalette[colourInd];
    item["pointHoverBorderColor"] = chromaPalette[colourInd];
    colourInd += 1;
  });

  return {
    datasets: datasets,
  };
};

const options = {
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      displayColors: false,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        // initial title of y axis since benchmark is the initial measure
        text: "Consumer Price Index",
      },
    },
    x: {
      type: "time",
      time: {
        parser: "YYYY-MM",
        tooltipFormat: "MMM, YYYY",
        unit: "month",
      },
      // min: '2002-01',
    },
  },
};

const CPI = () => {
  const sourcecode = "get_cpi";
  const { data: cpiData, isFetching, isError } = useRecord(sourcecode, null);

  const [selCategories, setSelCategories] = React.useState([
    "All-items",
    "All-items excluding food and energy",
    "All-items excluding energy",
  ]);

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelCategories(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={200} />}
      {cpiData?.length ? (
        <>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={7}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="category-multiple-checkbox-label">
                  Category
                </InputLabel>
                <Select
                  labelId="category-multiple-checkbox-label"
                  id="category-multiple-checkbox"
                  multiple
                  value={selCategories}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput label="category" />}
                  renderValue={(selected) =>
                    selected
                      .sort(function (a, b) {
                        return categories.indexOf(a) - categories.indexOf(b);
                      })
                      .join(", ")
                  }
                >
                  {categories.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={selCategories.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Line
                data={getChartData({ ...cpiData[0] }, selCategories)}
                options={options}
              />
            </Grid>
          </Grid>
          <DataSourceRef code={sourcecode} />
        </>
      ) : (
        <ErrorCard />
      )}
    </>
  );
};

export default CPI;
