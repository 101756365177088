import * as React from "react";

import { Grid, Box, Typography, Tab, Tabs } from "@mui/material";

import DashboardContainer from "../../layout/DashboardContainer";
import DashboardTitle from "../../typography/DashboardTitle";
import DashboardSubtitle from "../../typography/DashboardSubtitle";
import VisualizationTitle from "../../typography/VisualizationTitle";

import GoogleMobility from "../../indicators/mobility/GoogleMobility";
import AirTraffic from "../../indicators/mobility/AirTraffic";
import Ridership from "../../indicators/mobility/Ridership";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

const Mobility = (props) => {
  const [currTab, setCurrTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrTab(newValue);
  };

  const tabArray = [
    {
      label: "Roads and Bridges",
      source: <DataSourceRef code="get_kingston_capital_projects" />,
      component: (
        <iframe
          title="Roads and Bridges"
          width="100%"
          height="720"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          src="https://www.arcgis.com/apps/TimeAware/index.html?appid=1c68bb91a8904b7ca0941721ac20a64a"
        ></iframe>
      ),
    },
    {
      label: "Transportation Services",
      source: <DataSourceRef code="get_kingston_capital_projects" />,
      component: (
        <iframe
          title="Transportation Services"
          width="100%"
          height="720"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          src="https://www.arcgis.com/apps/TimeAware/index.html?appid=129e002e15c44019a53dbec3140d7bde"
        ></iframe>
      ),
    },
  ];
  return (
    <DashboardContainer sx={{ mt: 7 }}>
      <DashboardTitle sx={{ mb: 3 }}>{Mobility.title}</DashboardTitle>
      <Typography variant="body1" sx={{ mb: 5 }}>
        This dashboard explores how we move around our city and the places we
        go. The pandemic significantly disrupted our mobility. The shift to
        virtual work and school meant fewer people needing to travel to their
        jobs or schools. Additionally, the temporary closure of many facilities
        and businesses resulted in a decline in travelling to: activities,
        appointments, recreational opportunities, etc. As we move into a new era
        of living with COVID-19, the data captured below demonstrates how
        movement patterns were affected, have changed, or are undergoing
        adaptations to the ways we move and the places we travel.
      </Typography>
      <Grid container spacing={7} sx={{ mb: 7 }}>
        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Capital Projects</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 1 }}>
            The capital projects visualization demonstrates new investment in
            transportation infrastructure. Each year the City of Kingston has
            invested in new projects to enhance and better connect the road,
            path and trail networks to make it easier to move around the city.
          </DashboardSubtitle>
          <Box
            sx={{
              maxWidth: { xs: 320, sm: 700, md: "100%" },
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={currTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabArray.map((tabElem, index) => {
                return <Tab key={"tab-item-" + index} label={tabElem.label} />;
              })}
            </Tabs>
          </Box>
          {tabArray.map((tabElem, index) => {
            return (
              <Box
                key={index}
                sx={{ display: index === currTab ? "block" : "none" }}
              >
                {tabElem.component}
                {tabElem.source}
              </Box>
            );
          })}
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Ridership Data</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows daily ridership of public transit between January
            2019 and February 2022. 2019 illustrates the pre-pandemic norm with
            fluctuations in transit use over holidays and weekends, and
            increases during the winter months. At the onset of the COVID-19
            pandemic, ridership takes a steep decline and has stayed
            consistently at a lower level since. During the pandemic, the data
            shows further declines in transit use that likely correspond to the
            various waves of the pandemic. For example, in January 2022 during
            the Omicron variant, there is a notable decline.
          </DashboardSubtitle>
          <Ridership />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            Canadian Passenger Travel by Airplane
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            The passenger travel by airplane graph shows the number of
            passengers by year and by sector. At the onset of the COVID-19
            pandemic, it is evident that there is a remarkable decrease in the
            number of airplane travellers due to travelling restrictions,
            closing borders, and the efforts to curb the nature of COVID-19’s
            infection.
          </DashboardSubtitle>
          <AirTraffic
            sector={[
              "Total, passenger sector",
              "Domestic sector",
              "Transborder sector",
              "Other International sector",
            ]}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            Travel to Retail and Recreation
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            Travel to retail and recreation activities has largely been impacted
            over the past two years of the pandemic. This graph shows daily
            travel between March 2020 at the onset of the COVID-19 pandemic to
            present, and illustrates the impact of public health measures and
            pandemic waves through peak declines in the data. For example,
            travel in January 2022 takes a steep drop when provincial public
            health measures were in place due to the Omicron variant.
          </DashboardSubtitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            *Data is collected through google map's location histories and does
            not specify the mode of transportation.
            <br />
            *The baseline is the median between Jan 3-feb 6 2020, prior to the
            pandemic.
          </DashboardSubtitle>
          <GoogleMobility
            industry={"retail_and_recreation_percent_change_from_baseline"}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            Travel to Pharmacies and Grocery Stores
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows travel to grocery stores and pharmacies from March
            2020 at the onset of the COVID-19 pandemic to present. The data
            remains relatively close to baseline with a few periods where travel
            declined, which likely was a result of different pandemic waves.
            This data doesn’t show significant change possibly because
            pharmacies and grocery stores stayed open during the entirety of the
            pandemic. The notable points of decline can be correlated to
            holidays.
          </DashboardSubtitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            *Data is collected through google map's location histories and does
            not specify the mode of transportation.
            <br />
            *The baseline is the median between Jan 3-feb 6 2020, prior to the
            pandemic.
          </DashboardSubtitle>
          <GoogleMobility
            industry={"grocery_and_pharmacy_percent_change_from_baseline"}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Travel to Parks</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph measures travel to parks from March 2020 at the onset of
            the COVID-19 pandemic to present. The data illustrates seasonal
            changes in park use. During the summer months, between
            June-September, there is a peak in its travel, and during the winter
            trips to parks remains either at baseline or drops below.
          </DashboardSubtitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            *Data is collected through google map's location histories and does
            not specify the mode of transportation.
            <br />
            *The baseline is the median between Jan 3-feb 6 2020, prior to the
            pandemic.
          </DashboardSubtitle>
          <GoogleMobility industry={"parks_percent_change_from_baseline"} />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>
            Travel to Transit Stations - bus and train
          </VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows trips to transit stations from March 2020 at the
            onset of the COVID-19 pandemic to present. The data illustrates a
            rapid decline in travel to transit stations at the beginning of the
            pandemic, and maintained low levels throughout its duration.
            Currently, travel to transit stations is trending upwards but has
            not yet returned to baseline levels.
          </DashboardSubtitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            *Data is collected through google map's location histories and does
            not specify the mode of transportation.
            <br />
            *The baseline is the median between Jan 3-feb 6 2020, prior to the
            pandemic.
          </DashboardSubtitle>
          <GoogleMobility
            industry={"transit_stations_percent_change_from_baseline"}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Travel to Work</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            This graph shows trips to work from March 2020 at the onset of the
            COVID-19 pandemic to present. The data demonstrates the sudden
            decline in travel to work at the beginning of the pandemic by
            showing the significant drop below baseline. Given that this is
            daily data, weekends and holidays skew the data. Data points closer
            to the baseline represent weekends, and data points that are the
            furthest below baseline represent holidays. Aside from these data
            points, the overall trend is a decline in trips to work that has
            continued throughout the duration of the pandemic.
          </DashboardSubtitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            *Data is collected through google map's location histories and does
            not specify the mode of transportation.
            <br />
            *The baseline is the median between Jan 3-feb 6 2020, prior to the
            pandemic.
          </DashboardSubtitle>
          <GoogleMobility
            industry={"workplaces_percent_change_from_baseline"}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <VisualizationTitle>Trips to Home</VisualizationTitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            Overall, there is an increase in trips to and from home between
            March 2020 and present. This could indicate a few scenarios: it is
            possible that during the pandemic, people stayed closer to home
            meaning that they were making more local trips with home as their
            return destination; it could also mean that people were making more
            one-stop trips. For example, running an errand and then coming back
            home. The data shows a seasonal trend where trips home declined
            during the summer. This could be a result of summer vacations or
            trips where people were spending more time away from home and for
            longer periods.
          </DashboardSubtitle>
          <DashboardSubtitle sx={{ mb: 3 }}>
            *Data is collected through google map's location histories and does
            not specify the mode of transportation.
            <br />
            *The baseline is the median between Jan 3-feb 6 2020, prior to the
            pandemic.
          </DashboardSubtitle>
          <GoogleMobility
            industry={"residential_percent_change_from_baseline"}
          />
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default Mobility;

Mobility.path = "/dashboards/mobility";
Mobility.title = "Mobility";
Mobility.description =
  "This dashboard explores indicators for measuring mobility patterns and trends and examines the impact of the COVID-19 pandemic on moving around Kingston.";
