import React from "react";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Snackbar,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { useRecord } from "../../../api/records";
import palette from "../../../helpers/palette";
import ErrorCard from "../../common/ErrorCard";
import onlyUnique from "../../../helpers/dataManipulation";
import DataSourceRef from "../../common/footnotes/DataSourceRef";

function getMeasureStyle(key) {
  key = String(key);
  const chromaPalette = palette(3);
  switch (key) {
    case "1 partner country":
      return {
        colour: chromaPalette[0],
      };
    case "2 or more partner countries":
      return {
        colour: chromaPalette[1],
      };
    case "All partner countries":
      return {
        colour: chromaPalette[2],
      };
    default:
      return {
        colour: chromaPalette[0], // Fallback or default colour
      };
  }
  // return { colour: chromaPalette[name.length % chromaPalette.length] };
}

function getChartData(importdata, scalar_factor, geo, partner_countries) {
  importdata.data = importdata.data
    .filter((obj) => obj["GEO"].trim() === geo)
    .filter((obj) => obj["SCALAR_FACTOR"].trim() === scalar_factor)
    .filter(
      (obj) => partner_countries.indexOf(obj["Partner countries"].trim()) > -1,
    )
    .filter((obj) => typeof obj["VALUE"] === "number");

  const datasets = partner_countries.map((par_coun) => {
    const part_data = importdata.data
      .filter((obj) => obj["Partner countries"].trim() === par_coun)
      .map((obj) => ({ x: obj["REF_DATE"].toString(), y: obj["VALUE"] }));
    return {
      backgroundColor: getMeasureStyle(par_coun).colour,
      borderColor: getMeasureStyle(par_coun).colour,
      tension: 0.1,
      label: par_coun,
      data: part_data,
    };
  });

  return {
    datasets: datasets,
  };
}

const options = {
  scales: {
    y: {
      title: {
        display: true,
        text: "Number of exports",
      },
    },
    x: {
      type: "time",
      time: {
        parser: "YYYY",
        tooltipFormat: "YYYY",
        unit: "year",
      },
    },
  },
  maintainAspectRatio: false,
};

// function getUniqueGeo(data) {
//   return ["Kingston, Ontario"];
// }

function getUniqueScalarFactor(data, geo) {
  return data
    .filter((obj) => obj["GEO"].trim() === geo)
    .filter((obj) => typeof obj["VALUE"] === "number")
    .map((o) => o["SCALAR_FACTOR"])
    .filter(onlyUnique);
}

function getUniquePartnerCountries(data, geo, scalar_factor) {
  return data
    .filter((obj) => obj["GEO"].trim() === geo)
    .filter((obj) => obj["SCALAR_FACTOR"].trim() === scalar_factor)
    .filter((obj) => typeof obj["VALUE"] === "number")
    .map((o) => o["Partner countries"])
    .filter(onlyUnique);
}

export default function ValueImports() {
  const sourceCode = "get_import";
  const { data: importdata, isFetching, isError } = useRecord(sourceCode, null);

  const [selectedGeo] = React.useState("Kingston, Ontario");
  const [scalarfactor, setScalarFactor] = React.useState("thousands");
  const [partners, setPartners] = React.useState(["All partner countries"]);

  const checkPart = (newScalar, newGeo = selectedGeo) => {
    if (importdata?.data) {
      const partnerOpts = getUniquePartnerCountries(
        importdata[0].data,
        newGeo,
        newScalar,
      );
      const updatedParts = partners.filter(
        (part) => partnerOpts.indexOf(part) >= 0,
      );
      setPartners(updatedParts.length === 0 ? [partnerOpts[0]] : updatedParts);
    }
  };

  // const checkScalar = (newGeo) => {
  //   if (importdata?.data) {
  //     const scalarOpts = getUniqueScalarFactor(importdata[0].data, newGeo);
  //     if (scalarOpts.indexOf(scalarfactor) === -1) {
  //       setScalarFactor(scalarOpts[0]);
  //       checkPart(scalarOpts[0], newGeo);
  //     } else {
  //       checkPart(scalarfactor, newGeo);
  //     }
  //   }
  // };

  // const handleGeoChange = (event) => {
  //   const newGeo = event.target.value;
  //   setSelectedGeo(newGeo);
  //   checkScalar(newGeo);
  // };

  const handleScalarChange = (event) => {
    const newScalar = event.target.value;
    setScalarFactor(newScalar);
    checkPart(newScalar);
  };

  const handlePartChange = (event) => {
    const value = event.target.value;
    setPartners(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      {isError && (
        <Snackbar
          open={true}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Something went wrong. Please try reloading the page.
          </Alert>
        </Snackbar>
      )}
      {isFetching && <Skeleton variant="rectangular" height={500} />}
      {importdata?.length ? (
        <>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {/* <Grid item xs={12} lg={3}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="geo-simple-select-label">Geography</InputLabel>
                <Select
                  labelId="geo-simple-select-label"
                  id="geo-simple-select"
                  value={selectedGeo}
                  label="Geography"
                  onChange={handleGeoChange}
                >
                  {getUniqueGeo(importdata[0].data).map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="scalar-simple-select-label">Scalar</InputLabel>
                <Select
                  labelId="scalar-simple-select-label"
                  id="scalar-simple-select"
                  value={scalarfactor}
                  label="Scalar"
                  onChange={handleScalarChange}
                >
                  {getUniqueScalarFactor(importdata[0].data, selectedGeo)
                    .sort()
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="partner-multiple-checkbox-label">
                  Partner
                </InputLabel>
                <Select
                  labelId="partner-multiple-checkbox-label"
                  id="partner-multiple-checkbox"
                  multiple
                  value={partners}
                  onChange={handlePartChange}
                  input={<OutlinedInput label="Partner" />}
                  renderValue={(selected) => selected.sort().join(", ")}
                >
                  {getUniquePartnerCountries(
                    importdata[0].data,
                    selectedGeo,
                    scalarfactor,
                  )
                    .sort()
                    .map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={partners.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ minHeight: "30em" }}>
            <Line
              data={getChartData(
                { ...importdata[0] },
                scalarfactor,
                selectedGeo,
                partners,
              )}
              options={options}
            />
          </Box>
          <DataSourceRef code={sourceCode} />
        </>
      ) : (
        <ErrorCard />
      )}
    </>
  );
}
