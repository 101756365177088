// ["#54478c","#2c699a","#048ba8","#0db39e","#16db93","#83e377","#b9e769","#efea5a","#f1c453","#f29e4c", "#f28b4b"]

import chroma from "chroma-js";

export default function palette(quantityInput) {
  if (!quantityInput) {
    return [];
  }

  let quantity;

  if (typeof quantityInput === "string") {
    quantity = parseInt(quantityInput);
  } else {
    quantity = quantityInput;
  }

  return chroma
    .scale(["#54478c", "#83e377", "#f28b4b"])
    .mode("lch")
    .colors(quantity);
}
