import { Grid, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import DataSourceRef from "../../common/footnotes/DataSourceRef";
import { useState } from "react";

const FireandRescue = (props) => {
  const [callType, setCallType] = useState("");
  const [callYear, setCallYear] = useState("");

  const handleCallTypeChange = (event) => {
    setCallType(event.target.value);
  };
  const handleCallYearChange = (event) => {
    setCallYear(event.target.value);
  };

  const getCurrentYear = () => new Date().getFullYear();
  const generateYears = (startYear) => {
    const currentYear = getCurrentYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const years = generateYears(2016);

  const iframeSrc =
    `https://opendatakingston.cityofkingston.ca/explore/embed/dataset/kfr-incidents/map/?disjunctive.call_type&disjunctive.incident_type` +
    (callType === "" ? "" : `&refine.call_type=${callType}`) +
    (callYear === "" ? "" : `&refine.alarm_time=${callYear}`) +
    `&location=12,44.30342,-76.46834&basemap=72525b&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6Imtmci1pbmNpZGVudHMiLCJvcHRpb25zIjp7ImRpc2p1bmN0aXZlLmNhbGxfdHlwZSI6dHJ1ZSwiZGlzanVuY3RpdmUuaW5jaWRlbnRfdHlwZSI6dHJ1ZSwicmVmaW5lLmNhbGxfdHlwZSI6Ik1lZGljYWwifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6ImxpbmUiLCJmdW5jIjoiQ09VTlQiLCJ5QXhpcyI6InVuaXRfY291bnQiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjNjZjMmE1In1dLCJ4QXhpcyI6ImNhbGxfdHlwZSIsIm1heHBvaW50cyI6bnVsbCwidGltZXNjYWxlIjoiIiwic29ydCI6IiIsInNlcmllc0JyZWFrZG93biI6IiIsInNlcmllc0JyZWFrZG93blRpbWVzY2FsZSI6IiJ9XSwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZSwidGltZXNjYWxlIjoiIn0%3D&static=false&datasetcard=false&scrollWheelZoom=false`;

  return (
    <Grid item xs={12} lg={12}>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="call-type-label">Call Type</InputLabel>
            <Select
              labelId="call-type-label"
              id="call-type"
              value={callType}
              label="Call Type"
              onChange={handleCallTypeChange}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value={"False Fire Call"}>False Fire Call</MenuItem>
              <MenuItem value={"Medical"}>Medical</MenuItem>
              <MenuItem value={"Fire"}>Fire</MenuItem>
              <MenuItem value={"Motor Vehicle Accident"}>
                Motor Vehicle Accident
              </MenuItem>
              <MenuItem value={"CO Alarm"}>CO Alarm</MenuItem>
              <MenuItem value={"Structure Rescue"}>Structure Rescue</MenuItem>
              <MenuItem value={"Other Rescue"}>Other Rescue</MenuItem>
              <MenuItem value={"Water Rescue"}>Water Rescue</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="call-year-label">Call Year</InputLabel>
            <Select
              labelId="call-year-label"
              id="call-year"
              value={callYear}
              label="Call Year"
              onChange={handleCallYearChange}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {years.map((year) => (
                <MenuItem key={year} value={year.toString()}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <iframe
        src={iframeSrc}
        title="Kingston Fire and Rescue Incidents "
        width="100%"
        height="500"
        frameborder="0"
      ></iframe>
      <DataSourceRef code="kfr_incidents" />
    </Grid>
  );
};

export default FireandRescue;
