import { useQuery } from "react-query";
import api from "./api";

export const fetchSource = async (id = null, code = null) => {
  const { data } = await api.get("sources", {
    params: {
      id: id,
      code: code,
    },
  });
  return data;
};

export const useSource = (id, code) => {
  return useQuery([id, code], () => fetchSource(id, code));
};
